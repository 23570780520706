import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EnumCabinetMirrors, setExternalLeds } from "../../../store/cabinetMirror";
import { setExternalLedFiyatı } from "../../../store/cabinetMirrorFee";

const LavaboTavan = () => {
  const dispatch = useDispatch();
  const { externalLeds } = useSelector((state) => state.cabinetMirror);
  const { selectedMirror } = useSelector((state) => state.mirror);

  useEffect(() => {
    dispatch(setExternalLeds(0));
    dispatch(setExternalLedFiyatı(0));
  }, [selectedMirror]);

  const handleChange = (index) => {
    dispatch(setExternalLeds(index));
    dispatch(setExternalLedFiyatı(index === 1 ? 89 : index === 2 ? 89 : index === 3 ? 149 : index === 4 ? 149 : index === 5 ? 298 : 0));
  };

  const optionsArray = [
    { value: 0, label: "---" },
    { value: 1, label: "Sink + 89,00 €" },
    { value: 2, label: "Ceiling + 89,00 €" },
    { value: 3, label: "Sink & Ceiling + 149,00 €" },
    { value: 4, label: "Right & Left + 149,00 €" },
    { value: 5, label: "All Around + 298,00 €" },
  ];

  return (
    <div className="flex flex-col items-center w-full gap-2">
      {optionsArray.map((option, index) => (
        <button
          onClick={() => handleChange(index)}
          className={`w-full h-9 text-base rounded-full ${externalLeds === index ? "bg-primary text-secondary" : "bg-secondary text-primary"} flex items-center justify-center`}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
};

export default LavaboTavan;
