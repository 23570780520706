import { Html } from "@react-three/drei";
import React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const TestMenuMesh = ({ posX, posX2, openLeft, openRight, rightDoor, leftDoor, isLeftSided, testCabinet }) => {
  const { camCikintiAlt } = useSelector((state) => state.cabinetMirror);
  const handleDoorOpenning = () => {
    if (!isLeftSided) {
      openRight(!rightDoor);
      openLeft(false); // menteşe yönü değiştiğinde karışıklığa yol açmasın diye
    } else {
      openLeft(!leftDoor);
      openRight(false); // menteşe yönü değiştiğinde karışıklığa yol açmasın diye
    }
  };

  const newPosition = [posX - 1.05, -0.45 - camCikintiAlt * 0.00005, 0.1];
  return (
    <mesh position={newPosition}>
      <Html>
        <div
          className="bg-secondary"
          style={{
            width: 35,
            height: 35,
            borderRadius: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={handleDoorOpenning}
        >
          <span
            style={{
              fontSize: "1.1em",
            }}
          >
            <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" svg-inline="" role="presentation" focusable="false" tabindex="-1" className="outline-none">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2 2h17v15l-8 2.5V17H2V2zm.944 7.26c1.23.828 2.924 1.42 4.859 1.67l-1.821 1.373.677.899 3.444-2.595-2.596-3.444-.898.677 1.503 1.995c-2.257-.266-4.114-1.026-5.168-2V3h15.04L11 5.183V16H2.944V9.26zM12 18.213V6l6-2V16.28l-6 1.932z"
                fill="#F2DCBE"
              ></path>
            </svg>
          </span>
        </div>
      </Html>
    </mesh>
  );
};

export default TestMenuMesh;
