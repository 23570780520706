import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  baseFee: 0,
  suspansiyonFee: 0,
  ledIsigiFee: 0,
  ciftLedFee: 0,
  yataySensorFee: 0,
  kareSensorFee: 0,
  isiSensorFee: 0,
  saatFee: 0,
  soketFee: 0,
  hangingPlatesFee: 0,
  sealingFee: 0,
  makyajFee: 0,
  makyajTypeFee: 0,
  bluetoothFee: 0,
  sizdirmazlikFee: 0,
  aynaIsitmaFee: 0,
  makyajIsikFee: 0,
  rafFee: 0,
  facetaFee: 0,
  yanTamKaplamaFee: 0,
  gecisAnahtariSensorFee: 0,
};

const mirrorFee = createSlice({
  name: "mirrorFee",
  initialState: initialState,
  reducers: {
    setBaseFee: (state, action) => {
      state.baseFee = action.payload;
    },
    setSuspansiyonFee: (state, action) => {
      state.suspansiyonFee = action.payload;
    },
    setYanTamKaplamaFee: (state, action) => {
      state.yanTamKaplamaFee = action.payload;
    },
    setFacetaFee: (state, action) => {
      state.facetaFee = action.payload;
    },
    setRafFee: (state, action) => {
      state.rafFee = action.payload;
    },
    setAynaIsitmaFee: (state, action) => {
      state.aynaIsitmaFee = action.payload;
    },
    setLedIsigiFee: (state, action) => {
      state.ledIsigiFee = action.payload;
    },
    setCiftLedFee: (state, action) => {
      state.ciftLedFee = action.payload;
    },
    setYataySensorFee: (state, action) => {
      state.yataySensorFee = action.payload;
    },
    setKareSensorFee: (state, action) => {
      state.kareSensorFee = action.payload;
    },
    setGecisAnahtariSensorFee: (state, action) => {
      state.gecisAnahtariSensorFee = action.payload;
    },
    setIsiSensorFee: (state, action) => {
      state.isiSensorFee = action.payload;
    },
    setSaatFee: (state, action) => {
      state.saatFee = action.payload;
    },
    setSoketFee: (state, action) => {
      state.soketFee = action.payload;
    },
    setHangingPlatesFee: (state, action) => {
      state.hangingPlatesFee = action.payload;
    },
    setSealingFee: (state, action) => {
      state.sealingFee = action.payload;
    },
    setMakyajFee: (state, action) => {
      state.makyajFee = action.payload;
    },
    setMakyajTypeFee: (state, action) => {
      state.makyajTypeFee = action.payload;
    },
    setMakyajIsikFee: (state, action) => {
      state.makyajIsikFee = action.payload;
    },
    setBluetoothFee: (state, action) => {
      state.bluetoothFee = action.payload;
    },
    setSizdirmazlikFee: (state, action) => {
      state.sizdirmazlikFee = action.payload;
    },
  },
});

export const {
  setHangingPlatesFee,
  setBaseFee,
  setSuspansiyonFee,
  setLedIsigiFee,
  setCiftLedFee,
  setYataySensorFee,
  setKareSensorFee,
  setIsiSensorFee,
  setSaatFee,
  setSoketFee,
  setSealingFee,
  setMakyajFee,
  setBluetoothFee,
  setSizdirmazlikFee,
  setAynaIsitmaFee,
  setMakyajIsikFee,
  setRafFee,
  setFacetaFee,
  setYanTamKaplamaFee,
  setMakyajTypeFee,
  setGecisAnahtariSensorFee,
} = mirrorFee.actions;

export default mirrorFee.reducer;
