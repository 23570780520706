import React from "react";

import DresdenMirror from "./DresdenMirror";

import OxfordMirror from "./OxfordMirror";

import { useSelector } from "react-redux";
import { EnumCabinetMirrors } from "../../../store/cabinetMirror";

const CabinetLeftDoor = ({ type }) => {
  const { selectedMirror } = useSelector((state) => state.mirror);

  const isOnlyMirror = selectedMirror === EnumCabinetMirrors.BRISTOL;
  const isDresdenPattern =
    selectedMirror === EnumCabinetMirrors.DRESDEN || selectedMirror === EnumCabinetMirrors.LECH || selectedMirror === EnumCabinetMirrors.VILLACH || selectedMirror === EnumCabinetMirrors.LINZ;

  return isOnlyMirror ? <OxfordMirror /> : isDresdenPattern ? <DresdenMirror type={type} /> : null;
};

export default CabinetLeftDoor;
