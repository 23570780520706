import React, { useEffect } from "react";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useSelector, useDispatch } from "react-redux";
import { setOrderAmount } from "../../../store/cabinetMirror";

const AynaAdedi = () => {
  const { orderAmount } = useSelector((state) => state.cabinetMirror);
  const { selectedMirror } = useSelector((state) => state.mirror);

  const dispatch = useDispatch();
  const handleInc = () => {
    dispatch(setOrderAmount(orderAmount + 1));
  };
  const handleDec = () => {
    orderAmount > 1 && dispatch(setOrderAmount(orderAmount - 1));
  };

  useEffect(() => {
    dispatch(setOrderAmount(1));
  }, [selectedMirror]);

  return (
    <div className="flex flex-row items-center justify-center w-full gap-8">
      <button
        disabled={orderAmount > 1 ? false : true}
        onClick={handleDec}
        className={`w-8 h-8 text-base rounded-full ${orderAmount > 1 ? "bg-secondary" : "bg-[#00000040]"}  text-primary flex items-center justify-center cursor-pointer`}
      >
        <RemoveIcon fontSize="1rem" />
      </button>
      <p className="text-2xl"> {orderAmount} </p>
      <button onClick={handleInc} className={`w-8 h-8 text-base rounded-full bg-secondary text-primary flex items-center justify-center cursor-pointer`}>
        <AddIcon fontSize="1rem" />
      </button>
    </div>
  );
};

export default AynaAdedi;
