import { Avatar } from "@mui/material";

import { setBodyDecor } from "../../../store/cabinetMirror";
import { useDispatch, useSelector } from "react-redux";

import { renklerKategorili } from "../../../utils/data/data";
import { setDekorFiyatı } from "../../../store/cabinetMirrorFee";

const GovdeDoku = () => {
  //TODO RENKLER ARRAYDEN GELSIN

  const dispatch = useDispatch();
  const { bodyDecor } = useSelector((state) => state.cabinetMirror);

  return (
    <div className="flex flex-col items-center w-full gap-2">
      {renklerKategorili.slice(3, 60).map((item, index) => {
        const spliteItemTitle = item.title.split(" ");
        const imageName = spliteItemTitle[0] + "_" + spliteItemTitle[1] + ".jpeg";
        const imageLink = "./decors/" + imageName + "";

        let parts = item.title.split(" "); // Split by white space into an array

        let finalTitle = parts.slice(0, 2).join(" "); // Keep only the first two parts and join them back into a string
        const itemTitle = finalTitle + " - €" + item.price;

        const value = `${imageName},${item.price},${item.value}`;

        return (
          <button
            onClick={() => {
              const splitValue = value.split(",");
              const imageName = splitValue[0];
              const price = parseFloat(splitValue[1]);
              dispatch(setBodyDecor(imageName));
              dispatch(setDekorFiyatı(price));
            }}
            className={`w-full px-4 h-9 text-base rounded-full ${imageName === bodyDecor ? "bg-primary text-secondary" : "bg-secondary text-primary"} flex items-center justify-between`}
          >
            {itemTitle}
            <Avatar
              alt={item.title}
              src={imageLink}
              sx={{
                height: "1.5rem",
                width: "1.5rem",
              }}
            />
          </button>
        );
      })}
    </div>
  );
};

export default GovdeDoku;
