import { createSlice, current } from "@reduxjs/toolkit";
export const EnumCabinets = {
  //TODO Enum Mirrors Spiegel diye belirleyelim
  BHS002A: 4162,
  BHS011: 8806,
  BHS003: 4168,
  BHS005: 4171,
  BHS001: 4165,
  BHS002B: 4174,
  BHS010: 8803,
  BHS006: 4177,
  BHS009L4: 4183,
  BHS007L4: 4186,
  BHS012L4: 8809,
  BHS008L4: 4189,
  BHS013L4: 8812,
  BHS004: 4180,
};

let initialCabinetState = {
  cabinetWidth: 400,
  cabinetHeight: 2000,
  cabinetDepth: 125,
  cabinetType: -0.15,
  borderWidth: 100,
  borderHeight: 0,
  textureIndex: 0, // bu silinecek değiştirdikten sonra
  //Menü için sonradan eklenenler\\
  lightColor: [100, 100, 100],
  lightColorIndex: 0,
  lightIsOpen: 0,
  bodyDecor: "H1176_ST37.jpeg",
  frontBodyDecor: "H1137_ST12.jpeg",
  backPanel: 0,
  externalLeds: 0,
  bluetoothSpeaker: false,
  socketUsb: 0,
  socketUsbDisarida: 0,
  summaryModalOpen: false,
  orderAmount: 1,
  customerComment: "",
  doorHandle: "4764",
  doorHandlePos: 0,
  camCikintiUst: 1,
  camCikintiAlt: 1,
  ahsapZemin: 0,
  draggerVisible: 0,
  shelfCount: 4,
  selectedCabinetName: "",
  selectedCabinetExcelIndex: EnumCabinets.BHS006,
  doorOpeningSide: "left",
  isFirstLeftSided: false,
  isSecondLeftSided: false,
  openAllDoors: false,
  widthCmVisible: false,
  heightCmVisible: false,
  depthCmVisible: false,
  montajCevresiDerinligi: 70,
  kurulumCercevesi: false,
};

const cabinet = createSlice({
  name: "Cabinet",
  initialState: initialCabinetState,
  reducers: {
    setIsFirstLeftSided(state, action) {
      state.isFirstLeftSided = action.payload;
    },
    setMontajCevresiDerinligi(state, action) {
      state.montajCevresiDerinligi = action.payload;
    },
    setIsSecondLeftSided(state, action) {
      state.isSecondLeftSided = action.payload;
    },
    changeKurulumCercevesi(state, action) {
      state.kurulumCercevesi = action.payload;
    },
    changeCabinetWidth(state, action) {
      state.cabinetWidth = action.payload;
    },
    changeSelectedCabinetExcelItem(state, action) {
      const { id, name } = action.payload;
      state.selectedCabinetName = name;
      state.selectedCabinetExcelIndex = id;
    },
    changeCabinetHeight(state, action) {
      state.cabinetHeight = action.payload;
    },
    changeCabinetType(state, action) {
      state.cabinetType = action.payload;
    },
    changeCabinetDepth(state, action) {
      state.cabinetDepth = action.payload;
    },
    changeBorderWidth(state, action) {
      state.borderWidth = action.payload;
    },
    changeBorderHeight(state, action) {
      state.borderHeight = action.payload;
    },
    changeTextureIndex(state, action) {
      state.textureIndex = action.payload;
    },
    setTextureIndex: (state, action) => {
      state.textureIndex = action.payload;
    }, //// Bunu aşağıdaki bodyDecor ile değiştirmemiz gerekiyor

    ////MENU ICIN SONRADAN EKLENENLER\\\\
    setLightColor: (state, action) => {
      state.lightColor = action.payload;
    },
    setBodyDecor: (state, action) => {
      state.bodyDecor = action.payload;
    },
    setFrontBodyDecor: (state, action) => {
      state.frontBodyDecor = action.payload;
    },
    setBackPanel: (state, action) => {
      state.backPanel = action.payload;
    },
    setBluetoothSpeaker: (state, action) => {
      state.bluetoothSpeaker = action.payload;
    },
    setHasMakeupMirror: (state, action) => {
      state.hasMakeupMirror = action.payload;
    },
    setSocketUsb: (state, action) => {
      state.socketUsb = action.payload;
    },
    setSummaryModalOpen: (state, action) => {
      state.summaryModalOpen = action.payload;
    },
    setOrderAmount: (state, action) => {
      state.orderAmount = action.payload;
    },
    setCustomerComment: (state, action) => {
      state.customerComment = action.payload;
    },
    setDoorHandle: (state, action) => {
      state.doorHandle = action.payload;
    },
    setDoorHandlePos: (state, action) => {
      state.doorHandlePos = action.payload;
    },
    setCamCikintiUst: (state, action) => {
      state.camCikintiUst = action.payload;
    },
    setCamCikintiAlt: (state, action) => {
      state.camCikintiAlt = action.payload;
    },
    setAhsapZemin: (state, action) => {
      state.ahsapZemin = action.payload;
    },
    setSensor: (state, action) => {
      state.sensor = action.payload;
    },
    //--
    setShelfCount: (state, action) => {
      state.shelfCount = action.payload;
    },
    setDoorOpeningSide: (state, action) => {
      state.doorOpeningSide = action.payload;
    },
    changeLightStatus(state, action) {
      if (action.payload == false) {
        state.lightIsOpen = false;
      } else {
        state.lightIsOpen = !state.lightIsOpen;
      }
    },
    changeLightColor(state, action) {
      state.lightColorIndex = action.payload;
      if (action.payload === 0) {
        // WHITE
        state.lightColor = [100, 100, 100];
      } else if (action.payload === 1) {
        // SICAK BEYAZ
        state.lightColor = [100, 100, 0.5];
      } else if (action.payload === 2) {
        // BLUE
        state.lightColor = [-100, 100, 100];
      } else {
        state.lightColor = [100, 100, 100];
      }
    },
    setOpenAllDoors: (state, action) => {
      state.openAllDoors = !state.openAllDoors;
    },
    changeHeightCmVisible: (state, action) => {
      state.heightCmVisible = !state.heightCmVisible;
    },
    changeWidthCmVisible: (state, action) => {
      state.widthCmVisible = !state.widthCmVisible;
    },
    changeDepthCmVisible: (state, action) => {
      state.depthCmVisible = !state.depthCmVisible;
    },
    resetToInitialState: (state) => {
      state.cabinetDepth = 0;
      state.cabinetWidth = 0;
      state.cabinetHeight = 0;
      state.cabinetType = -0.15;
      state.borderWidth = 100;
      state.borderHeight = 0;
      state.textureIndex = 0;
      state.lightColor = 0;
      state.lightColorIndex = 0;
      state.lightIsOpen = false;
      state.bodyDecor = 0;
      state.backPanel = 0;
      state.socketUsb = "default";
      state.socketUsbDisarida = "default";
      state.summaryModalOpen = false;
      state.orderAmount = 1;
      state.customerComment = "";
      state.doorHandle = 0;
      state.doorHandlePos = 0;
      state.camCikintiUst = 1;
      state.camCikintiAlt = 1;
      state.ahsapZemin = 0;
      state.draggerVisible = 0;
      state.openAllDoors = false;
      state.kurulumCercevesi = 0;
      state.montajCevresiDerinligi = 70;
    },
  },
});

export let {
  setMontajCevresiDerinligi,
  setIsFirstLeftSided,
  setIsSecondLeftSided,
  changeCabinetDepth,
  changeCabinetHeight,
  changeCabinetWidth,
  changeCabinetType,
  changeBorderWidth,
  changeBorderHeight,
  changeTextureIndex,
  disableMirror,
  setLightColor,
  setKurulumCercevesi,
  setBodyDecor,
  setFrontBodyDecor,
  setBackPanel,
  setSocketUsb,
  setSocketUsbDisarida,
  setSummaryModalOpen,
  increaseOrderAmount,
  decreaseOrderAmount,
  setOrderAmount,
  setCustomerComment,
  resetToInitialState,
  setDoorHandle,
  setDoorHandlePos,
  setCamCikintiAlt,
  setCamCikintiUst,
  setAhsapZemin,
  setSensor,
  setShelfCount,
  changeLightColor,
  changeLightStatus,
  changeSelectedCabinetExcelItem,
  setDoorOpeningSide,
  setOpenAllDoors,
  changeHeightCmVisible,
  changeWidthCmVisible,
  changeDepthCmVisible,
  changeKurulumCercevesi,
} = cabinet.actions;

export default cabinet.reducer;
