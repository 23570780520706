import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { setAhsapZemin, setShelfCount } from "../../../store/cabinetMirror";
import { setAhsapZeminFiyatı, setCamRafFiyatı } from "../../../store/cabinetMirrorFee";

const AhsapZeminler = () => {
  const dispatch = useDispatch();
  const { ahsapZemin } = useSelector((state) => state.cabinetMirror);
  const { selectedMirror } = useSelector((state) => state.mirror);

  const handleChange = (index) => {
    dispatch(setAhsapZemin(index));
    dispatch(setAhsapZeminFiyatı(index === 1 ? 35 : index === 2 ? 50 : index === 3 ? 60 : index === 4 ? 70 : index === 5 ? 80 : index === 6 ? 90 : 0));
    dispatch(setShelfCount(0));
    dispatch(setCamRafFiyatı(0));
  };

  useEffect(() => {
    dispatch(setAhsapZemin(3));
    dispatch(setAhsapZeminFiyatı(60));
    dispatch(setShelfCount(0));
    dispatch(setCamRafFiyatı(0));
  }, [selectedMirror]);

  const optionsArray = [
    {
      value: 0,
      text: "None",
    },
    {
      value: 1,
      text: "1x Shelf + 35,00 €",
    },
    {
      value: 2,
      text: "2x Shelves + 50,00 €",
    },
    {
      value: 3,
      text: "3x Shelves + 60,00 €",
    },
    {
      value: 4,
      text: "4x Shelves + 70,00 €",
    },
    {
      value: 5,
      text: "5x Shelves + 80,00 €",
    },
    {
      value: 6,
      text: "6x Shelves + 90,00 €",
    },
  ];

  return (
    <div className="flex flex-col items-center w-full gap-2">
      {optionsArray.map((option, index) => (
        <button
          onClick={() => handleChange(index)}
          className={`w-full h-9 text-base rounded-full ${ahsapZemin === index ? "bg-primary text-secondary" : "bg-secondary text-primary"} flex items-center justify-center`}
        >
          {option.text}
        </button>
      ))}
    </div>
  );
};

export default AhsapZeminler;
