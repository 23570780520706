import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeLightColor } from "../../../store/cabinetMirror";
import { setLedIsikFiyatı } from "../../../store/cabinetMirrorFee";

const LedIsıgı = () => {
  const dispatch = useDispatch();
  const { lightColorIndex } = useSelector((state) => state.cabinetMirror);
  const { selectedMirror } = useSelector((state) => state.mirror);

  const handleChange = (index) => {
    dispatch(changeLightColor(index));
    dispatch(setLedIsikFiyatı(index === 3 ? 79.99 : index === 4 ? 179.99 : index === 5 ? 159.0 : 0));
  };

  useEffect(() => {
    dispatch(changeLightColor(0));
    dispatch(setLedIsikFiyatı(0));
  }, [selectedMirror]);

  const optionsArray = [
    { label: "Neutral White 4000K", value: 0 },
    { label: "Warm White 3000K", value: 1 },
    { label: "Cold White 6000K", value: 2 },
  ];

  return (
    <div className="flex flex-col items-center w-full gap-2">
      {optionsArray.map((option, index) => (
        <button
          onClick={() => handleChange(index)}
          className={`w-full h-9 text-base rounded-full ${lightColorIndex === index ? "bg-primary text-secondary" : "bg-secondary text-primary"} flex items-center justify-center`}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
};

export default LedIsıgı;
