import { useGLTF, useTexture, Effects } from "@react-three/drei";

import React, { useRef, useEffect } from "react";
import { useSelector } from "react-redux";

const RightHinge = () => {
  const { nodes, materials } = useGLTF("/Frankfurt-test2.glb");
  const { cabinetMirrorWidth } = useSelector((state) => state.cabinetMirror);

  return (
    <mesh
      name="hingeBodyLeft"
      castShadow
      receiveShadow
      geometry={nodes.hingeBodyRight.geometry}
      material={materials.aluminum}
      position={[0.22 + cabinetMirrorWidth * 0.000005, 0, 0.11]}
      scale={[cabinetMirrorWidth == 400 ? 1 : 1 - cabinetMirrorWidth * 0.00008, 1, 1]}
    />
  );
};

export default RightHinge;
