import { Html } from "@react-three/drei";
import React from "react";
import { useSelector } from "react-redux";

const IndividualWidth = ({ width, testCabinet }) => {
  const { cabinetMirrorWidth, cabinetMirrorHeight, doorNumber } = useSelector((state) => state.cabinetMirror);

  const newWidth = ((width / (cabinetMirrorWidth / doorNumber / 200)) * (cabinetMirrorWidth / doorNumber)).toFixed(0);
  const oldWidth = ((width * cabinetMirrorWidth) / doorNumber).toFixed(0);
  const widthValue = testCabinet ? newWidth : oldWidth;

  const htmlPosX = testCabinet ? -0.2 : 0.85 + ((width * cabinetMirrorWidth) / doorNumber) * 0.0003;

  return (
    <Html position={[htmlPosX, 0.6 + cabinetMirrorHeight * 0.000175, 0]}>
      <div
        style={{
          backgroundColor: "white",
          fontSize: cabinetMirrorWidth < 700 ? "12px" : "16px",
          padding: "4px 8px",
          borderRadius: "8px",
          width: "auto",
          whiteSpace: "nowrap",
        }}
      >
        <p>{widthValue} mm</p>
      </div>
    </Html>
  );
};

export default IndividualWidth;
