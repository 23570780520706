import { useDispatch, useSelector } from "react-redux";
import { changeCabinetMirrorWidth, setCustomDoorWidthValues } from "../../../store/cabinetMirror";
import { useState, useEffect } from "react";
import { Stack } from "@mui/material";

const Genislik = () => {
  const dispatch = useDispatch();
  const { doorNumber } = useSelector((state) => state.cabinetMirror);

  const { selectedMirror } = useSelector((state) => state.mirror);

  const [input, setInput] = useState(400);
  const [alertMessage, setAlertMessage] = useState(false);
  const minValue = 400;
  const maxValue = 1950;

  useEffect(() => {
    dispatch(changeCabinetMirrorWidth(maxValue));
    setInput(maxValue);
    const initialValue = (minValue / doorNumber).toFixed(0);
    dispatch(setCustomDoorWidthValues([initialValue, initialValue, initialValue]));
  }, [selectedMirror]);

  const handleChange = (e) => {
    setInput(e.target.value);
    if (e.target.value < minValue || e.target.value > maxValue) {
      setAlertMessage(true);
    } else {
      setAlertMessage(false);
      dispatch(changeCabinetMirrorWidth(e.target.value));
    }
  };

  return (
    <>
      <div>
        <input className="text-center text-lg bg-transparent border-b outline-none focus:bg-transparent border-secondary" type="number" value={input} onChange={handleChange} />
      </div>
      {alertMessage ? (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          sx={{
            position: "relative",
            top: -10,
          }}
        >
          <small
            style={{
              color: "red",
            }}
          >
            Please enter the height value between {minValue} and {maxValue}
          </small>
        </Stack>
      ) : null}
    </>
  );
};

export default Genislik;
