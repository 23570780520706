import React, { useEffect } from "react";
import { useGLTF } from "@react-three/drei";
import { useSelector } from "react-redux";

export const EnumSocketUSBBase = {
  LEFT: "left",
  MID: "mid",
  RIGHT: "right",
};

function DimmerSocketUSB({ base }) {
  const { nodes: oneDimmerNodes, materials: oneDimmerMaterials } = useGLTF("/socketUSB/oneDimmer.glb");
  const { nodes: oneDimmerOneSocketNodes, materials: oneDimmerOneSocketMaterials } = useGLTF("/socketUSB/oneDimmerOneSocket.glb");
  const { nodes: oneDimmerTwoSocketNodes, materials: oneDimmerTwoSocketMaterials } = useGLTF("/socketUSB/oneDimmerTwoSocket.glb");
  const { nodes: oneDimmerOneSocketOneUsbNodes, materials: oneDimmerOneSocketOneUsbMaterials } = useGLTF("/socketUSB/oneDimmerOneSocketOneUsb.glb");
  const { nodes: oneSwitchNodes, materials: oneSwitchMaterials } = useGLTF("/socketUSB/oneSwitch.glb");
  const { nodes: oneSwitchOneSocketNodes, materials: oneSwitchOneSocketMaterials } = useGLTF("/socketUSB/oneSwitchOneSocket.glb");
  const { nodes: oneSwitchTwoSocketNodes, materials: oneSwitchTwoSocketMaterials } = useGLTF("/socketUSB/oneSwitchTwoSocket.glb");
  const { nodes: oneSwitchOneSocketOneUsbNodes, materials: oneSwitchOneSocketOneUsbMaterials } = useGLTF("/socketUSB/oneSwitchOneSocketOneUsb.glb");

  //------------------------------------------------------------------------------------------------------------------------------------------------------------------------

  const { sensor, cabinetMirrorWidth, cabinetMirrorHeight } = useSelector((state) => state.cabinetMirror);
  const oneDimmer = () => (
    <group scale={[0.12, 0.2 - ((cabinetMirrorHeight - 400) / 250) * 0.02, 0.15 - ((cabinetMirrorWidth - 400) / 300) * 0.015]} position={[0, -0.3, -0.07]}>
      <mesh castShadow receiveShadow geometry={oneDimmerNodes.ElektroAbdeckungRechts002004.geometry} material={oneDimmerMaterials["wire_214228153.004"]} />
      <mesh castShadow receiveShadow geometry={oneDimmerNodes.ElektroAbdeckungRechts002004_1.geometry} material={oneDimmerMaterials["wire_184155229.001"]} />
    </group>
  );
  const oneDimmerOneSocket = () => (
    <group scale={[0.12, 0.2 - ((cabinetMirrorHeight - 400) / 250) * 0.02, 0.15 - ((cabinetMirrorWidth - 400) / 300) * 0.015]} position={[0, -0.3, -0.07]}>
      <mesh castShadow receiveShadow geometry={oneDimmerOneSocketNodes.ElektroAbdeckungRechts002001.geometry} material={oneDimmerOneSocketMaterials["wire_214228153.004"]} />
      <mesh castShadow receiveShadow geometry={oneDimmerOneSocketNodes.ElektroAbdeckungRechts002001_1.geometry} material={oneDimmerOneSocketMaterials["wire_204204204.004"]} />
      <mesh castShadow receiveShadow geometry={oneDimmerOneSocketNodes.ElektroAbdeckungRechts002004.geometry} material={oneDimmerOneSocketMaterials["wire_214228153.004"]} />
      <mesh castShadow receiveShadow geometry={oneDimmerOneSocketNodes.ElektroAbdeckungRechts002004_1.geometry} material={oneDimmerOneSocketMaterials["wire_184155229.001"]} />
    </group>
  );
  const oneDimmerOneSocketOneUsb = () => (
    <group scale={[0.12, 0.2 - ((cabinetMirrorHeight - 400) / 250) * 0.02, 0.15 - ((cabinetMirrorWidth - 400) / 300) * 0.015]} position={[0, -0.3, -0.07]}>
      <mesh castShadow receiveShadow geometry={oneDimmerOneSocketOneUsbNodes.ElektroAbdeckungRechts002001.geometry} material={oneDimmerOneSocketOneUsbMaterials["wire_214228153.004"]} />
      <mesh castShadow receiveShadow geometry={oneDimmerOneSocketOneUsbNodes.ElektroAbdeckungRechts002001_1.geometry} material={oneDimmerOneSocketOneUsbMaterials["wire_204204204.004"]} />
      <mesh castShadow receiveShadow geometry={oneDimmerOneSocketOneUsbNodes.ElektroAbdeckungRechts002004.geometry} material={oneDimmerOneSocketOneUsbMaterials["wire_214228153.004"]} />
      <mesh castShadow receiveShadow geometry={oneDimmerOneSocketOneUsbNodes.ElektroAbdeckungRechts002004_1.geometry} material={oneDimmerOneSocketOneUsbMaterials["wire_184155229.001"]} />
      <mesh castShadow receiveShadow geometry={oneDimmerOneSocketOneUsbNodes.ElektroAbdeckungRechts002006.geometry} material={oneDimmerOneSocketOneUsbMaterials["wire_214228153.005"]} />
      <mesh castShadow receiveShadow geometry={oneDimmerOneSocketOneUsbNodes.ElektroAbdeckungRechts002006_1.geometry} material={oneDimmerOneSocketOneUsbMaterials["wire_184155229.002"]} />
      <mesh castShadow receiveShadow geometry={oneDimmerOneSocketOneUsbNodes.ElektroAbdeckungRechts002006_2.geometry} material={oneDimmerOneSocketOneUsbMaterials["wire_214228153.004"]} />
      <mesh castShadow receiveShadow geometry={oneDimmerOneSocketOneUsbNodes.ElektroAbdeckungRechts002006_3.geometry} material={oneDimmerOneSocketOneUsbMaterials["wire_204204204.004"]} />
      <mesh castShadow receiveShadow geometry={oneDimmerOneSocketOneUsbNodes.ElektroAbdeckungRechts002006_4.geometry} material={oneDimmerOneSocketOneUsbMaterials["Colour_Void.003"]} />
    </group>
  );
  const oneDimmerTwoSocket = () => (
    <group scale={[0.12, 0.2 - ((cabinetMirrorHeight - 400) / 250) * 0.02, 0.15 - ((cabinetMirrorWidth - 400) / 300) * 0.015]} position={[0, -0.3, -0.07]}>
      <group position={[0.368, 0, 0]}>
        <mesh castShadow receiveShadow geometry={oneDimmerTwoSocketNodes.ElektroAbdeckungRechts002002.geometry} material={oneDimmerTwoSocketMaterials["wire_214228153.007"]} />
        <mesh castShadow receiveShadow geometry={oneDimmerTwoSocketNodes.ElektroAbdeckungRechts002002_1.geometry} material={oneDimmerTwoSocketMaterials["wire_204204204.007"]} />
      </group>
      <mesh castShadow receiveShadow geometry={oneDimmerTwoSocketNodes.ElektroAbdeckungRechts002001.geometry} material={oneDimmerTwoSocketMaterials["wire_214228153.004"]} />
      <mesh castShadow receiveShadow geometry={oneDimmerTwoSocketNodes.ElektroAbdeckungRechts002001_1.geometry} material={oneDimmerTwoSocketMaterials["wire_204204204.004"]} />
      <mesh castShadow receiveShadow geometry={oneDimmerTwoSocketNodes.ElektroAbdeckungRechts002004.geometry} material={oneDimmerTwoSocketMaterials["wire_214228153.004"]} />
      <mesh castShadow receiveShadow geometry={oneDimmerTwoSocketNodes.ElektroAbdeckungRechts002004_1.geometry} material={oneDimmerTwoSocketMaterials["wire_184155229.001"]} />
    </group>
  );

  //------------------------------------------------------------------------------------------------------------------------------------------------------------------------

  const oneSwitch = () => (
    <group scale={[0.12, 0.2 - ((cabinetMirrorHeight - 400) / 250) * 0.02, 0.15 - ((cabinetMirrorWidth - 400) / 300) * 0.015]} position={[0, -0.3, -0.07]}>
      <mesh castShadow receiveShadow geometry={oneSwitchNodes["switch"].geometry} material={oneSwitchMaterials["wire_214228153.007"]} />
    </group>
  );
  const oneSwitchOneSocket = () => (
    <group scale={[0.12, 0.2 - ((cabinetMirrorHeight - 400) / 250) * 0.02, 0.15 - ((cabinetMirrorWidth - 400) / 300) * 0.015]} position={[0, -0.3, -0.07]}>
      <group rotation={[0, -1.571, 0]} scale={[15.068, 15.501, 15.501]}>
        <mesh castShadow receiveShadow geometry={oneSwitchOneSocketNodes.ElektroAbdeckungRechts002001.geometry} material={oneSwitchOneSocketMaterials["wire_214228153.004"]} />
        <mesh castShadow receiveShadow geometry={oneSwitchOneSocketNodes.ElektroAbdeckungRechts002001_1.geometry} material={oneSwitchOneSocketMaterials["wire_204204204.004"]} />
      </group>
      <mesh castShadow receiveShadow geometry={oneSwitchOneSocketNodes["switch"].geometry} material={oneSwitchOneSocketMaterials["wire_214228153.007"]} />
    </group>
  );
  const oneSwitchOneSocketOneUsb = () => (
    <group scale={[0.12, 0.2 - ((cabinetMirrorHeight - 400) / 250) * 0.02, 0.15 - ((cabinetMirrorWidth - 400) / 300) * 0.015]} position={[0, -0.3, -0.07]}>
      <group rotation={[0, -1.571, 0]} scale={[15.068, 15.501, 15.501]}>
        <mesh castShadow receiveShadow geometry={oneSwitchOneSocketOneUsbNodes.ElektroAbdeckungRechts002001.geometry} material={oneSwitchOneSocketOneUsbMaterials["wire_214228153.004"]} />
        <mesh castShadow receiveShadow geometry={oneSwitchOneSocketOneUsbNodes.ElektroAbdeckungRechts002001_1.geometry} material={oneSwitchOneSocketOneUsbMaterials["wire_204204204.004"]} />
      </group>
      <group scale={[0.978, 0.978, 0.879]}>
        <mesh castShadow receiveShadow geometry={oneSwitchOneSocketOneUsbNodes.ElektroAbdeckungRechts002006.geometry} material={oneSwitchOneSocketOneUsbMaterials["wire_214228153.005"]} />
        <mesh castShadow receiveShadow geometry={oneSwitchOneSocketOneUsbNodes.ElektroAbdeckungRechts002006_1.geometry} material={oneSwitchOneSocketOneUsbMaterials["wire_184155229.002"]} />
        <mesh castShadow receiveShadow geometry={oneSwitchOneSocketOneUsbNodes.ElektroAbdeckungRechts002006_2.geometry} material={oneSwitchOneSocketOneUsbMaterials["wire_214228153.004"]} />
        <mesh castShadow receiveShadow geometry={oneSwitchOneSocketOneUsbNodes.ElektroAbdeckungRechts002006_3.geometry} material={oneSwitchOneSocketOneUsbMaterials["wire_204204204.004"]} />
        <mesh castShadow receiveShadow geometry={oneSwitchOneSocketOneUsbNodes.ElektroAbdeckungRechts002006_4.geometry} material={oneSwitchOneSocketOneUsbMaterials["Colour_Void.003"]} />
      </group>
      <mesh castShadow receiveShadow geometry={oneSwitchOneSocketOneUsbNodes["switch"].geometry} material={oneSwitchOneSocketOneUsbMaterials["wire_214228153.007"]} />
    </group>
  );
  const oneSwitchTwoSocket = () => (
    <group scale={[0.12, 0.2 - ((cabinetMirrorHeight - 400) / 250) * 0.02, 0.15 - ((cabinetMirrorWidth - 400) / 300) * 0.015]} position={[0, -0.3, -0.07]}>
      <group rotation={[0, -1.571, 0]} scale={[15.068, 15.501, 15.501]}>
        <mesh castShadow receiveShadow geometry={oneSwitchTwoSocketNodes.ElektroAbdeckungRechts002001.geometry} material={oneSwitchTwoSocketMaterials["wire_214228153.004"]} />
        <mesh castShadow receiveShadow geometry={oneSwitchTwoSocketNodes.ElektroAbdeckungRechts002001_1.geometry} material={oneSwitchTwoSocketMaterials["wire_204204204.004"]} />
      </group>
      <mesh castShadow receiveShadow geometry={oneSwitchTwoSocketNodes["switch"].geometry} material={oneSwitchTwoSocketMaterials["wire_214228153.007"]} />
      <group position={[0.371, 0, 0]} rotation={[0, -1.571, 0]} scale={[15.068, 15.501, 15.501]}>
        <mesh castShadow receiveShadow geometry={oneSwitchTwoSocketNodes.ElektroAbdeckungRechts002003.geometry} material={oneSwitchTwoSocketMaterials["wire_214228153.008"]} />
        <mesh castShadow receiveShadow geometry={oneSwitchTwoSocketNodes.ElektroAbdeckungRechts002003_1.geometry} material={oneSwitchTwoSocketMaterials["wire_204204204.008"]} />
      </group>
    </group>
  );

  const isLEFT = base === EnumSocketUSBBase.LEFT;
  const isRIGHT = base === EnumSocketUSBBase.RIGHT;

  // eslint-disable-next-line default-case
  useEffect(() => {
    console.log(sensor);
  }, [sensor]);
  switch (true) {
    case sensor === "250" || sensor === "2319" || sensor === "2320" || sensor === "990" || sensor === "992":
      return null;
    case isLEFT && sensor === "6789":
      return oneSwitch();
    case isRIGHT && sensor === "756":
      return oneSwitch();
    case isLEFT && sensor === "6791":
      return oneDimmer();
    case isRIGHT && sensor === "6792":
      return oneDimmer();
    case isLEFT && sensor === "251":
      return oneSwitchOneSocket();
    case isRIGHT && sensor === "578":
      return oneSwitchOneSocket();
    case isLEFT && sensor === "6785":
      return oneSwitchTwoSocket();
    case isRIGHT && sensor === "6786":
      return oneSwitchTwoSocket();
    case isLEFT && sensor === "6787":
      return oneSwitchOneSocketOneUsb();
    case isRIGHT && sensor === "6788":
      return oneSwitchOneSocketOneUsb();
    case isLEFT && sensor === "6805":
      return oneDimmerOneSocket();
    case isRIGHT && sensor === "6806":
      return oneDimmerOneSocket();
    case isLEFT && sensor === "6807":
      return oneDimmerTwoSocket();
    case isRIGHT && sensor === "6808":
      return oneDimmerTwoSocket();
    case isLEFT && sensor === "6809":
      return oneDimmerOneSocketOneUsb();
    case isRIGHT && sensor === "6810":
      return oneDimmerOneSocketOneUsb();
    default:
      return null;
  }
}

export default DimmerSocketUSB;
