import { useSelector } from "react-redux";
import { Suspense, useState } from "react";

import TestCabinet from "./TestCabinet";
import { Environment, OrbitControls, Sky, Stage } from "@react-three/drei";

import TestRoom from "./TestRoom";

import { Bloom, EffectComposer } from "@react-three/postprocessing";

import { UnrealBloomPass } from "three-stdlib";

import { extend } from "@react-three/fiber";
import { Perf } from "r3f-perf";
import { useMediaQuery } from "@mui/material";

extend({ UnrealBloomPass });

const Test = () => {
  const { lightIsOpen } = useSelector((state) => state.cabinetMirror);

  const [isDragging, setIsDragging] = useState(false);

  const menuMobileMatches = useMediaQuery("(max-width:767px)");
  const menuMobileMatches2 = useMediaQuery("(max-width:526px)");

  let positionZ = -2.2;

  if (menuMobileMatches) {
    positionZ = -1.3;
  }

  if (menuMobileMatches2) {
    positionZ = -1.6;
  }

  return (
    <group
      position-y={1}
      position-z={positionZ}>
      {/* <Stage environment="apartment" /> */}
      {/* <Environment near={1} far={100} resolution={256} files={"./hdr/bathroom_360.hdr"} background /> */}
      <ambientLight
        intensity={0.5}
        color={"#f5edda"}
      />
      <pointLight
        position={[0, 5, 0]}
        intensity={0.5}
        color={"#f5edda"}
      />
      <Sky sunPosition={[0, 5, 0]} />
      <group rotation={[0, -1.5, 0]}>
        {lightIsOpen && (
          <EffectComposer
            multisampling={4}
            disableNormalPass>
            <Bloom
              luminanceThreshold={1}
              intensity={0.015}
              levels={2}
            />
            <Bloom
              luminanceThreshold={1}
              intensity={0.0053}
              levels={5}
              mipmapBlur
            />
            <Bloom
              luminanceThreshold={1}
              intensity={0.0019}
              levels={6}
              mipmapBlur
            />
          </EffectComposer>
        )}
        <Suspense fallback={null}>
          <TestCabinet
            setIsDragging={setIsDragging}
            isDragging={isDragging}
          />
        </Suspense>
        <TestRoom />
        {!isDragging && (
          <OrbitControls
            enablePan={false}
            minPolarAngle={Math.PI / 2.7}
            maxPolarAngle={Math.PI / 1.8}
            minAzimuthAngle={Math.PI * 1.35}
            maxAzimuthAngle={Math.PI * 1.75}
            zoomSpeed={0.25}
            enableZoom={true}
            makeDefault
            maxDistance={8}
            minDistance={3}
          />
        )}
        {/* <Perf /> */}
      </group>
    </group>
  );
};

export default Test;
