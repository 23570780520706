import { MeshReflectorMaterial, useGLTF } from "@react-three/drei";
import React from "react";
import * as THREE from "three";

const OxfordMirror = () => {
  const oxfordGeometry = useGLTF("/newModels/oxford.glb").nodes.rightDoor_2.geometry;

  return (
    <group>
      <mesh position-z={0.001} geometry={oxfordGeometry}>
        <MeshReflectorMaterial resolution={2048} blur={0} mixBlur={0} mirror={1} />
      </mesh>
      <mesh position-x={0.25} position-z={-0.003} rotation-y={Math.PI}>
        <meshBasicMaterial side={THREE.DoubleSide} color={"#ffffff"} />
        <planeGeometry args={[0.5, 0.78]} />
      </mesh>
    </group>
  );
};

export default OxfordMirror;
