import { MeshReflectorMaterial, useGLTF } from "@react-three/drei";
import React from "react";
import { useSelector } from "react-redux";
import * as THREE from "three";

const DresdenMirror = ({ type }) => {
  const { nodes, materials } = useGLTF("/newModels/dresden.glb");
  const { nodes: dresdenNodes } = useGLTF("/dresden.glb");
  const { lightColor } = useSelector((state) => state.cabinetMirror);

  return (
    <>
      <mesh position-z={0.001} position-x={0.25}>
        <MeshReflectorMaterial resolution={2048} blur={0} mixBlur={0} mirror={1} />
        <planeGeometry args={[0.5, 0.8]} />
      </mesh>
      <mesh position-x={0.25} position-z={-0.003} rotation-y={Math.PI}>
        <meshBasicMaterial color={"#ffffff"} />
        <planeGeometry args={[0.5, 0.78]} />
      </mesh>
      {type !== "mid" && type !== "single" && (
        <mesh
          rotation-x={type === "last" ? Math.PI * 0.5 : 0}
          position-x={type === "last" ? 0.3 : 0}
          position-z={type === "first" ? -0.0038 : 0.0005}
          geometry={type === "first" ? nodes.leftDoorLeft_4.geometry : dresdenNodes.ayna_kapak_kapalı_005_3.geometry}
          material={materials.ledtexture}
        >
          <meshBasicMaterial toneMapped={false} color={lightColor} />
        </mesh>
      )}
      {type === "single" && (
        <>
          <mesh scale-x={0.5} rotation-x={0} position-x={0.25} position-z={0.001} geometry={nodes.leftDoorRight_4.geometry} material={materials.ledtexture}>
            <meshBasicMaterial toneMapped={false} color={lightColor} />
          </mesh>
          <mesh scale-x={0.5} rotation-x={Math.PI * 0.5} position-x={0.4} position-z={0.005} geometry={dresdenNodes.ayna_kapak_kapalı_005_3.geometry} material={materials.ledtexture}>
            <meshBasicMaterial toneMapped={false} color={lightColor} />
          </mesh>
        </>
      )}

      {/* <mesh geometry={nodes.leftDoorLeft_5.geometry} material={materials["aluminum.001"]} /> */}
    </>
  );
};

export default DresdenMirror;
