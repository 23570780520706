import { useRef, useState, useEffect } from "react";

import gsap from "gsap";

import { useGLTF } from "@react-three/drei";

import { useFrame } from "@react-three/fiber";
import { useDrag } from "react-use-gesture";
import { useDispatch, useSelector } from "react-redux";

import DışAydınlatma from "../../OrtakComponents/DışAydınlatma";

import { EnumCabinetMirrors, setCustomDoorWidthValues } from "../../../store/cabinetMirror";

import CentimeterHeightLines from "../../OrtakComponents/CM/CentimeterHeightLines";
import CentimeterWidthLines from "../../OrtakComponents/CM/CentimeterWidthLines";
import CentimeterDepthLines from "../../OrtakComponents/CM/CentimeterDepthLines";

import { EnumSocketUSBBase } from "../cabinetBodyParts/SocketUSB";
import { CabinetTestBolmesi } from "./CabinetTestBolmesi";

export default function TestCabinet(props) {
  //MODELS
  const dispatch = useDispatch();
  //**AYNALI DOLAPLAR */
  const { nodes, materials } = useGLTF("/Frankfurt-test2.glb");
  const { nodes: budapestNodes } = useGLTF("/newModels/budapest.glb");

  // STATES
  const {
    lightColor,
    cabinetMirrorWidth,
    cabinetMirrorHeight,
    cabinetMirrorDepth,
    cabinetMirrorType,
    externalLeds,
    draggerVisible,
    isFirstLeftSided,
    isSecondLeftSided,
    isThirdLeftSided,
    isFourthLeftSided,
    isFifthLeftSided,
    isSixthLeftSided,
    widthCmVisible,
    heightCmVisible,
    depthCmVisible,
    doorNumber,
    bottomCompartmentHeight,
  } = useSelector((state) => state.cabinetMirror);

  //------REFS------\\

  const frankfurt = useRef();

  //----------First Cabinet
  const firstCabinet = useRef();

  //----------Second Cabinet
  const secondCabinet = useRef();

  //----------Third Cabinet
  const thirdCabinet = useRef();

  //----------Fourth Cabinet
  const fourthCabinet = useRef();

  //----------Fifth Cabinet
  const fifthCabinet = useRef();

  //----------Sixth Cabinet
  const sixthCabinet = useRef();

  //----------LEDS
  const outerLedRef = useRef();
  const innerLedRef = useRef();

  //--STATES--\\

  const { selectedMirror } = useSelector((state) => state.mirror);

  //-----Dragger States-----\\

  const iA12 = (cabinetMirrorWidth / doorNumber / 400) * 2 * 0.25;
  const iA23 = (cabinetMirrorWidth / doorNumber / 400) * 2 * 0.75;
  const iA34 = (cabinetMirrorWidth / doorNumber / 400) * 2 * 1.25;
  const iA45 = (cabinetMirrorWidth / doorNumber / 400) * 2 * 1.75;
  const iA56 = (cabinetMirrorWidth / doorNumber / 400) * 2 * 2.25;

  const [dragger12Position, setDragger12Position] = useState([iA12, 0, 0.115 + cabinetMirrorType]);
  const [dragger23Position, setDragger23Position] = useState([iA23, 0, 0.115 + cabinetMirrorType]);
  const [dragger34Position, setDragger34Position] = useState([iA34, 0, 0.115 + cabinetMirrorType]);
  const [dragger45Position, setDragger45Position] = useState([iA45, 0, 0.115 + cabinetMirrorType]);
  const [dragger56Position, setDragger56Position] = useState([iA56, 0, 0.115 + cabinetMirrorType]);

  const [dragger12PositionDiff, setDragger12PositionDiff] = useState(0);
  const [dragger23PositionDiff, setDragger23PositionDiff] = useState(0);
  const [dragger34PositionDiff, setDragger34PositionDiff] = useState(0);
  const [dragger45PositionDiff, setDragger45PositionDiff] = useState(0);
  const [dragger56PositionDiff, setDragger56PositionDiff] = useState(0);

  const [scaleDiff1, setScaleDiff1] = useState(0);
  const [scaleDiff2, setScaleDiff2] = useState(0);
  const [scaleDiff3, setScaleDiff3] = useState(0);
  const [scaleDiff4, setScaleDiff4] = useState(0);
  const [scaleDiff5, setScaleDiff5] = useState(0);
  const [scaleDiff6, setScaleDiff6] = useState(0);

  //----TestMenuMesh visibility states

  //----Dragger Functions

  const bindDragger12 = useDrag(
    ({ offset: [x], dragging }) => {
      const [, y, z] = dragger12Position;
      if (draggerVisible && x < 250 && x > -250 && 0.4 <= firstCabinet.current.scale.x) {
        setDragger12Position([iA12 + x / 1000, y, z]);
      }
      dragging ? props.setIsDragging(true) : props.setIsDragging(false);
    },
    { pointerEvents: true, damping: 0.02 }
  );

  const bindDragger23 = useDrag(
    ({ offset: [x], dragging }) => {
      const [, y, z] = dragger12Position;
      if (draggerVisible && x < 250 && x > -250 && 0.4 <= secondCabinet.current.scale.x) {
        setDragger23Position([iA23 + x / 1000, y, z]);
      }
      dragging ? props.setIsDragging(true) : props.setIsDragging(false);
    },
    { pointerEvents: true, damping: 0.02 }
  );

  const bindDragger34 = useDrag(
    ({ offset: [x], dragging }) => {
      const [, y, z] = dragger12Position;
      if (draggerVisible && x < 250 && x > -250 && 0.4 <= thirdCabinet.current.scale.x) {
        setDragger34Position([iA34 + x / 1000, y, z]);
      }
      dragging ? props.setIsDragging(true) : props.setIsDragging(false);
    },
    { pointerEvents: true, damping: 0.02 }
  );
  const bindDragger45 = useDrag(
    ({ offset: [x], dragging }) => {
      const [, y, z] = dragger12Position;
      if (draggerVisible && x < 250 && x > -250 && 0.4 <= fourthCabinet.current.scale.x) {
        setDragger45Position([iA45 + x / 1000, y, z]);
      }
      dragging ? props.setIsDragging(true) : props.setIsDragging(false);
    },
    { pointerEvents: true, damping: 0.02 }
  );
  const bindDragger56 = useDrag(
    ({ offset: [x], dragging }) => {
      const [, y, z] = dragger12Position;
      if (draggerVisible && x < 250 && x > -250 && 0.4 <= fifthCabinet.current.scale.x) {
        setDragger56Position([iA56 + x / 1000, y, z]);
      }
      dragging ? props.setIsDragging(true) : props.setIsDragging(false);
    },
    { pointerEvents: true, damping: 0.02 }
  );

  useEffect(() => {
    const iA12 = (cabinetMirrorWidth / doorNumber / 400) * 2 * 0.25;
    const iA23 = (cabinetMirrorWidth / doorNumber / 400) * 2 * 0.75;
    const iA34 = (cabinetMirrorWidth / doorNumber / 400) * 2 * 1.25;
    const iA45 = (cabinetMirrorWidth / doorNumber / 400) * 2 * 1.75;
    const iA56 = (cabinetMirrorWidth / doorNumber / 400) * 2 * 2.25;

    setDragger12Position((prev) => [iA12, 0, 0.115 + cabinetMirrorType]);
    setDragger23Position((prev) => [iA23, 0, 0.115 + cabinetMirrorType]);
    setDragger34Position((prev) => [iA34, 0, 0.115 + cabinetMirrorType]);
    setDragger45Position((prev) => [iA45, 0, 0.115 + cabinetMirrorType]);
    setDragger56Position((prev) => [iA56, 0, 0.115 + cabinetMirrorType]);
  }, [cabinetMirrorType, doorNumber, cabinetMirrorWidth]);

  useFrame((state) => {
    // -----CABINET SCALES----\\
    const initialScaleX = (cabinetMirrorWidth / doorNumber / 400) * 2;

    setScaleDiff1((firstCabinet.current.scale.x - initialScaleX) / 2);
    setScaleDiff2((secondCabinet.current.scale.x - initialScaleX) / 2);
    setScaleDiff3((thirdCabinet.current.scale.x - initialScaleX) / 2);
    setScaleDiff4((fourthCabinet.current.scale.x - initialScaleX) / 2);
    setScaleDiff5((fifthCabinet.current.scale.x - initialScaleX) / 2);
    setScaleDiff6((sixthCabinet.current.scale.x - initialScaleX) / 2);

    setDragger12PositionDiff(dragger12Position[0] - iA12);
    setDragger23PositionDiff(dragger23Position[0] - iA23);
    setDragger34PositionDiff(dragger34Position[0] - iA34);
    setDragger45PositionDiff(dragger45Position[0] - iA45);
    setDragger56PositionDiff(dragger56Position[0] - iA56);
    gsap.to(firstCabinet.current.scale, { x: initialScaleX + dragger12PositionDiff });
    gsap.to(secondCabinet.current.scale, {
      x: initialScaleX + dragger23PositionDiff - dragger12PositionDiff,
    });
    gsap.to(thirdCabinet.current.scale, {
      x: initialScaleX + dragger34PositionDiff - dragger23PositionDiff,
    });
    gsap.to(fourthCabinet.current.scale, {
      x: initialScaleX + dragger45PositionDiff - dragger34PositionDiff,
    });
    gsap.to(fifthCabinet.current.scale, {
      x: initialScaleX - dragger45PositionDiff + dragger56PositionDiff,
    });
    gsap.to(sixthCabinet.current.scale, { x: initialScaleX - dragger56PositionDiff });

    //** */
    gsap.to(firstCabinet.current.position, { x: initialScaleX * 0 + scaleDiff1 * 0.5 });
    gsap.to(secondCabinet.current.position, { x: initialScaleX * 0.5 + scaleDiff2 * 0.5 + dragger12PositionDiff * 0.5 });
    gsap.to(thirdCabinet.current.position, { x: initialScaleX * 1 + scaleDiff3 * 0.5 + dragger23PositionDiff * 0.5 });
    gsap.to(fourthCabinet.current.position, { x: initialScaleX * 1.5 + scaleDiff4 * 0.5 + dragger34PositionDiff * 0.5 });
    gsap.to(fifthCabinet.current.position, { x: initialScaleX * 2 + scaleDiff5 * 0.5 + dragger45PositionDiff * 0.5 });
    gsap.to(sixthCabinet.current.position, { x: initialScaleX * 2.5 + scaleDiff6 * 0.5 + dragger56PositionDiff * 0.5 });
  });

  useEffect(() => {
    frankfurt.current.scale.y = 1.5 + (cabinetMirrorHeight - 400) / 400;
    frankfurt.current.position.y = 0;
    frankfurt.current.position.x = -1;

    frankfurt.current.position.z = 0.2 + 0.065 * cabinetMirrorDepth * 0.00125;
    frankfurt.current.scale.z = 2 + cabinetMirrorDepth * 0.00225;

    if (cabinetMirrorWidth > 399) {
      frankfurt.current.position.x = 1.5;
    }
    if (cabinetMirrorWidth > 899) {
      frankfurt.current.position.x = 0.8;
    }
    if (cabinetMirrorWidth > 1199) {
      frankfurt.current.position.x = 0.3;
    }
    if (cabinetMirrorWidth > 1399) {
      frankfurt.current.position.x = 0.15;
    }
    if (cabinetMirrorWidth > 1699) {
      frankfurt.current.position.x = 0;
    }
  }, [cabinetMirrorWidth, cabinetMirrorHeight, cabinetMirrorDepth, cabinetMirrorType]);

  // };

  const isBottomAndTopLedVisible = externalLeds === 3;
  const isBottomLedVisible = isBottomAndTopLedVisible || externalLeds === 1;
  const isTopLedVisible = isBottomAndTopLedVisible || externalLeds === 2;

  const hasBottomShelf = [
    EnumCabinetMirrors.MUNCHEN_6,
    EnumCabinetMirrors.BUDAPEST,
    EnumCabinetMirrors.SANTANDER,
    EnumCabinetMirrors.LISSABON,
    EnumCabinetMirrors.LINZ,
    EnumCabinetMirrors.CORTINA,
    EnumCabinetMirrors.WINDSOR,
    EnumCabinetMirrors.VERONICA_2,
    EnumCabinetMirrors.PANDORA_2,
    EnumCabinetMirrors.KATHERINE_2,
  ].includes(selectedMirror);

  const hasPandora = [
    EnumCabinetMirrors.VERONICA_1,
    EnumCabinetMirrors.PANDORA_1,
    EnumCabinetMirrors.KATHERINE_1,
    EnumCabinetMirrors.VERONICA_2,
    EnumCabinetMirrors.PANDORA_2,
    EnumCabinetMirrors.KATHERINE_2,
  ].includes(selectedMirror);

  const hasInnerLed = ![EnumCabinetMirrors.OXFORD, EnumCabinetMirrors.WESER, EnumCabinetMirrors.ELBE_1, EnumCabinetMirrors.WINDSOR].includes(selectedMirror) && !hasPandora;

  const hasLedFrame = [EnumCabinetMirrors.HAMBURG, EnumCabinetMirrors.MUNCHEN_6, EnumCabinetMirrors.MUNCHEN_1, EnumCabinetMirrors.FRANKFURT, EnumCabinetMirrors.ELBE_1].includes(selectedMirror);

  // CM Adjustment for added cabinets

  // const widthCm = cabinet
  const [widthOfWidthCM, setWidthOfWidthCM] = useState(220 + cabinetMirrorWidth * 0.682);
  const [leftOfWidthCM, setLeftOfWidthCM] = useState(-235 - cabinetMirrorWidth * 0.2);
  const [textPosWidthCM, setTextPosWidthCM] = useState(-1.2 + cabinetMirrorWidth * 0.00032);
  const [positionOfDepthCM, setPositionOfDepthCM] = useState([-0.7 - cabinetMirrorWidth * 0.001, -0.53 - cabinetMirrorHeight * 0.0004, -0.18 - cabinetMirrorDepth * 0.0001]);

  useEffect(() => {
    if (doorNumber > 2) {
      setWidthOfWidthCM(-20 + cabinetMirrorWidth * 0.85);
      setLeftOfWidthCM(400 - cabinetMirrorWidth * 0.407);
      setTextPosWidthCM(1.47 + cabinetMirrorWidth * 0.00008);
      setPositionOfDepthCM((prev) => [-1.17 - cabinetMirrorWidth * 0.0004, -0.85 - cabinetMirrorHeight * 0.00008, prev[2]]);
    } else if (doorNumber > 1) {
      setWidthOfWidthCM(25 + cabinetMirrorWidth * 0.795);
      setLeftOfWidthCM(-337 - cabinetMirrorWidth * 0.26);
      setTextPosWidthCM(-0.75 + cabinetMirrorWidth * 0.0003);
      setPositionOfDepthCM((prev) => [-1.08 - cabinetMirrorWidth * 0.0007, -0.85 - cabinetMirrorHeight * 0.00008, prev[2]]);
    } else {
      setWidthOfWidthCM(38 + cabinetMirrorWidth * 0.75);
      setLeftOfWidthCM(-390 - cabinetMirrorWidth * 0.4);
      setTextPosWidthCM(-1.1 + cabinetMirrorWidth * 0.0003);
      setPositionOfDepthCM((prev) => [-1.15 - cabinetMirrorWidth * 0.00125, -0.85 - cabinetMirrorHeight * 0.00008, prev[2]]);
    }
  }, [cabinetMirrorWidth, widthCmVisible, cabinetMirrorHeight, cabinetMirrorDepth, doorNumber]);

  const [cmPositionOfZ, setCmPositionOfZ] = useState(0);
  useEffect(() => {
    // if (cabinetMirrorType === 0) {
    //   setCmPositionOfZ(0.3);
    //   setPositionOfDepthCM((prev) => [prev[0], prev[1], -0.18 - cabinetMirrorDepth * 0.0001]);
    // } else if (cabinetMirrorType === 0.2) {
    //   setCmPositionOfZ(0.7);
    //   setPositionOfDepthCM((prev) => [prev[0], prev[1], 0.27 - cabinetMirrorDepth * 0.0001]);
    // } else if (cabinetMirrorType === -0.08) {
    //   setCmPositionOfZ(0.1);
    //   setPositionOfDepthCM((prev) => [prev[0], prev[1], -0.37 - cabinetMirrorDepth * 0.0001]);
    // } else {
    setCmPositionOfZ(0.1);
    setPositionOfDepthCM((prev) => [prev[0], prev[1], -0.57 - cabinetMirrorDepth * 0.0001]);
    // }
  }, [cabinetMirrorType]);

  useEffect(() => {
    if (draggerVisible && props.isDragging) {
      const lengthArray = [];

      dispatch(setCustomDoorWidthValues(lengthArray));
    } else if (!draggerVisible) {
      setDragger12Position([iA12, 0, 0.115 + cabinetMirrorType]);
      setDragger23Position([iA23, 0, 0.115 + cabinetMirrorType]);
      setDragger34Position([iA34, 0, 0.115 + cabinetMirrorType]);
      setDragger45Position([iA45, 0, 0.115 + cabinetMirrorType]);
      setDragger56Position([iA56, 0, 0.115 + cabinetMirrorType]);
    }
  }, [props.isDragging, draggerVisible, doorNumber]);

  const multiplierBottomTopLed =
    cabinetMirrorWidth < 651 ? 30 : cabinetMirrorWidth < 901 ? 4 : cabinetMirrorWidth < 1002 ? 2.9 : cabinetMirrorWidth < 1600 ? 2.6 : cabinetMirrorWidth < 2000 ? 2.5 : 2.4;
  const bottomTopLedPosX = (cabinetMirrorWidth / doorNumber / 400) * (doorNumber / multiplierBottomTopLed) - 0.035;

  useEffect(() => {
    if (outerLedRef.current && innerLedRef.current) {
      if (hasBottomShelf) {
        const bottomCompScaleDiffRatio = (bottomCompartmentHeight - 100) / 200;
        const cabinetHeightRatio = (cabinetMirrorHeight - 600) / 600;
        const initialScaleYOuterLed = 1 + cabinetMirrorHeight * 0.000005;
        const initialScaleYInnerLed = 0.97 + cabinetMirrorHeight * 0.00003;
        if (bottomCompScaleDiffRatio <= 2) {
          //--OUTER-LED
          outerLedRef.current.scale.y = initialScaleYOuterLed + initialScaleYOuterLed * (-bottomCompScaleDiffRatio * 0.21 + cabinetHeightRatio * 0.07);
          outerLedRef.current.position.y = 0 + bottomCompScaleDiffRatio * 0.075 - cabinetHeightRatio * 0.0238;
          //--INNER-LED
          innerLedRef.current.scale.y = initialScaleYInnerLed + initialScaleYInnerLed * (-bottomCompScaleDiffRatio * 0.25 + cabinetHeightRatio * 0.075) * 0.8;
          innerLedRef.current.position.y = 0 + bottomCompScaleDiffRatio * 0.075 - cabinetHeightRatio * 0.0238;
        }
      } else {
        outerLedRef.current.position.y = 0;
        innerLedRef.current.position.y = 0;
      }
    }
  }, [cabinetMirrorHeight, bottomCompartmentHeight]);

  return (
    <>
      {/* {widthCmVisible && (
        <CentimeterWidthLines
          textWidth={cabinetMirrorWidth > 999 ? "150px" : "120x"}
          textPadding={cabinetMirrorWidth > 999 ? "0 21px" : "0 30px"}
          textValue={cabinetMirrorWidth == 0 ? "400" : cabinetMirrorWidth}
          textSize={"3em"}
          textHeight="75px"
          textPos={[textPosWidthCM, 0.9, 0.03]}
          lineLeft={leftOfWidthCM}
          lineWidth={widthOfWidthCM}
          scale={[0.12, doorNumber > 5 ? 0.4 : 0.3, 0.12]}
          position={[0, -0.35 + cabinetMirrorHeight * 0.001, cmPositionOfZ]}
        />
      )}
      {heightCmVisible && (
        <CentimeterHeightLines
          textWidth={cabinetMirrorHeight > 999 ? "150px" : "120x"}
          textPadding={cabinetMirrorHeight > 999 ? "0 21px" : "0 30px"}
          textValue={cabinetMirrorHeight == 0 ? "400" : cabinetMirrorHeight}
          textSize={"3em"}
          textHeight="75px"
          textPos={[0.52, 0.45, 0.06]}
          textTop={-35 + cabinetMirrorHeight / 200}
          lineTop={-55 - cabinetMirrorHeight * 0.35}
          lineHeight={122 + cabinetMirrorHeight * 0.678}
          scale={[cabinetMirrorHeight > 1500 ? 0.3 : 0.2, 0.12, 0.12]}
          position={[doorNumber * 0.05 + -0.7 + cabinetMirrorWidth * 0.0024, -0.43, cmPositionOfZ]}
        />
      )}
      {depthCmVisible && (
        <CentimeterDepthLines
          textWidth={"150"}
          textPadding={"0 30"}
          textValue={cabinetMirrorDepth == 0 ? "125" : cabinetMirrorDepth}
          textSize={"3em"}
          textHeight="75px"
          textPos={[0.13, 0.9, 0.24 + cabinetMirrorDepth * 0.00022]}
          lineLeft={4}
          lineWidth={130 + cabinetMirrorDepth * 0.15}
          scale={[0.12, doorNumber > 5 ? 0.4 : 0.3, 0.15]}
          position={positionOfDepthCM}
        />
      )} */}
      <group renderOrder={1} ref={frankfurt} {...props}>
        {/* LED ÇERÇEVE */}
        <group
          ref={outerLedRef}
          scale={[0.6668 + 0.6668 * ((cabinetMirrorWidth - 400) / 400), 1 + cabinetMirrorHeight * 0.000005, 1]}
          position={[((cabinetMirrorWidth / doorNumber / 400) * (doorNumber - 1)) / 2, 0, 0.116 + cabinetMirrorType]}
        >
          <mesh visible={hasLedFrame ? true : false} geometry={nodes.led_1.geometry} material={materials.aluminum} />
          <mesh visible={hasLedFrame ? true : false} geometry={nodes.led_2.geometry} material={materials.ledtexture}>
            <meshStandardMaterial color={lightColor} toneMapped={false} />
          </mesh>
        </group>

        {/* İÇ LED */}
        {hasInnerLed && (
          <mesh
            ref={innerLedRef}
            geometry={budapestNodes.interriorLed.geometry}
            material={materials.ledtexture}
            scale={[0.6 + 0.63 * ((cabinetMirrorWidth - 400) / 370), 0.97 + cabinetMirrorHeight * 0.00003, 1]}
            position={[((cabinetMirrorWidth / doorNumber / 400) * (doorNumber - 1)) / 2, 0, -0.02 + cabinetMirrorType]}
          >
            <meshBasicMaterial color={[lightColor[0] * 0.25, lightColor[1] * 0.25, lightColor[2] * 0.25]} toneMapped={false} />
          </mesh>
        )}

        {/* DIŞARIDAKİ LEDLER */}

        {isBottomLedVisible && (
          <DışAydınlatma
            visible={isBottomLedVisible}
            scaleY={cabinetMirrorWidth * 0.002}
            position={[bottomTopLedPosX, hasBottomShelf ? -0.56 : -0.406, 0.06 + cabinetMirrorType]}
            rotation={[Math.PI * 0.5, 0, Math.PI * 0.5]}
          />
        )}
        {isTopLedVisible && (
          <DışAydınlatma visible={isTopLedVisible} scaleY={cabinetMirrorWidth * 0.002} position={[bottomTopLedPosX, 0.405, 0.06 + cabinetMirrorType]} rotation={[Math.PI * 1.5, 0, Math.PI * 1.5]} />
        )}
        {/* FIRST CABINET */}
        <CabinetTestBolmesi cabinetRef={firstCabinet} doorIndex={0} isLeftSided={isFirstLeftSided} socketUsbBase={EnumSocketUSBBase.LEFT} doorType={doorNumber === 1 ? "single" : "first"} />
        {draggerVisible !== 0 && doorNumber > 1 && (
          <mesh rotation-y={Math.PI * 0.5} position={dragger12Position} visible={draggerVisible === 0 ? false : true} {...bindDragger12()}>
            <boxGeometry args={[0.025, 0.2, 0.05]} />
            <meshBasicMaterial color={"#575757"} />
          </mesh>
        )}
        {/* SECOND CABINET */}
        <CabinetTestBolmesi
          cabinetRef={secondCabinet}
          doorIndex={1}
          isLeftSided={isSecondLeftSided}
          socketUsbBase={doorNumber > 2 && doorNumber < 5 ? EnumSocketUSBBase.MID : doorNumber === 2 ? EnumSocketUSBBase.RIGHT : ""}
          doorType={doorNumber > 2 ? "mid" : "last"}
        />
        {draggerVisible !== 0 && doorNumber > 2 && (
          <mesh rotation-y={Math.PI * 0.5} position={dragger23Position} {...bindDragger23()} visible={draggerVisible === 0 ? false : true}>
            <boxGeometry args={[0.025, 0.2, 0.05]} />
            <meshBasicMaterial color={"#575757"} />
          </mesh>
        )}
        {/* THIRD CABINET */}
        <CabinetTestBolmesi
          cabinetRef={thirdCabinet}
          doorIndex={2}
          isLeftSided={isThirdLeftSided}
          socketUsbBase={doorNumber === 3 ? EnumSocketUSBBase.RIGHT : doorNumber > 3 && doorNumber < 6 ? EnumSocketUSBBase.MID : ""}
          doorType={doorNumber > 3 ? "mid" : "last"}
        />
        {draggerVisible !== 0 && doorNumber > 3 && (
          <mesh rotation-y={Math.PI * 0.5} position={dragger34Position} {...bindDragger34()} visible={draggerVisible === 0 ? false : true}>
            <boxGeometry args={[0.025, 0.2, 0.05]} />
            <meshBasicMaterial color={"#575757"} />
          </mesh>
        )}
        {/* FOURTH CABINET */}

        <CabinetTestBolmesi
          cabinetRef={fourthCabinet}
          doorIndex={3}
          isLeftSided={isFourthLeftSided}
          socketUsbBase={doorNumber < 5 ? EnumSocketUSBBase.RIGHT : doorNumber === 6 ? EnumSocketUSBBase.MID : ""}
          doorType={doorNumber > 4 ? "mid" : "last"}
        />
        {draggerVisible !== 0 && doorNumber > 4 && (
          <mesh rotation-y={Math.PI * 0.5} position={dragger45Position} {...bindDragger45()} visible={draggerVisible === 0 ? false : true}>
            <boxGeometry args={[0.025, 0.2, 0.05]} />
            <meshBasicMaterial color={"#575757"} />
          </mesh>
        )}
        {/* FIFTH CABINET */}

        <CabinetTestBolmesi
          cabinetRef={fifthCabinet}
          doorIndex={4}
          isLeftSided={isFifthLeftSided}
          socketUsbBase={doorNumber > 5 ? "" : EnumSocketUSBBase.RIGHT}
          doorType={doorNumber > 5 ? "mid" : "last"}
        />
        {draggerVisible !== 0 && doorNumber > 5 && (
          <mesh rotation-y={Math.PI * 0.5} position={dragger56Position} {...bindDragger56()} visible={draggerVisible === 0 ? false : true}>
            <boxGeometry args={[0.025, 0.2, 0.05]} />
            <meshBasicMaterial color={"#575757"} />
          </mesh>
        )}

        {/* SIXTH CABINET */}

        <CabinetTestBolmesi cabinetRef={sixthCabinet} doorIndex={5} isLeftSided={isSixthLeftSided} socketUsbBase={EnumSocketUSBBase.RIGHT} doorType={"last"} />
      </group>
    </>
  );
}

// useGLTF.preload("/Frankfurt-test2.glb");
