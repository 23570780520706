import React, { useEffect, useRef } from "react";
import { Stack, Typography, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setIsFirstLeftSided, setIsSecondLeftSided, setIsThirdLeftSided, setIsFourthLeftSided, setIsFifthLeftSided, setIsSixthLeftSided, EnumCabinetMirrors } from "../../../store/cabinetMirror";

const MenteseYonu = () => {
  const dispatch = useDispatch();
  const { isFirstLeftSided, isSecondLeftSided, isThirdLeftSided, isFourthLeftSided, isFifthLeftSided, isSixthLeftSided } = useSelector((state) => state.cabinetMirror);
  const { selectedMirror } = useSelector((state) => state.mirror);

  const menuRef = useRef(null);

  const testDoorsInfoArray = [
    //TODO MUNCHEN ICIN FARKLI ARRAYLAR OLUSTURURUZ
    {
      value: isFirstLeftSided,
      setter: (value) => dispatch(setIsFirstLeftSided(value)),
      entegrationInputValue: isFirstLeftSided ? 2583 : 2584,
      name: "id[245]",
      label: "Door 1",
    },
    {
      value: isSecondLeftSided,
      setter: (value) => dispatch(setIsSecondLeftSided(value)),
      entegrationInputValue: isSecondLeftSided ? 2586 : 2585,
      name: "id[246]",
      label: "Door 2",
    },
    {
      value: isThirdLeftSided,
      setter: (value) => dispatch(setIsThirdLeftSided(value)),
      entegrationInputValue: isThirdLeftSided ? 2587 : 2588,
      name: "id[247]",
      label: "Door 3",
    },
    {
      value: isFourthLeftSided,
      setter: (value) => dispatch(setIsFourthLeftSided(value)),
      entegrationInputValue: isFourthLeftSided ? 2590 : 2589,
      name: "id[248]",
      label: "Door 4",
    },
    {
      value: isFifthLeftSided,
      setter: (value) => dispatch(setIsFifthLeftSided(value)),
      entegrationInputValue: isFifthLeftSided ? 2591 : 2592,
      name: "id[249]",
      label: "Door 5",
    },
    {
      value: isSixthLeftSided,
      setter: (value) => dispatch(setIsSixthLeftSided(value)),
      name: "id[250]",
      entegrationInputValue: isSixthLeftSided ? 2594 : 2593,
      label: "Door 6",
    },
  ];

  const isSidedCabinet = selectedMirror === EnumCabinetMirrors.LAS_VEGAS || selectedMirror === EnumCabinetMirrors.MUNCHEN_4;

  const doorsInfoArray = isSidedCabinet ? testDoorsInfoArray : testDoorsInfoArray; //Test ettşn sided cabinetlerde de aynı şekilde munchen 4 de test ettim

  return (
    <div className="flex flex-col items-center w-full gap-2">
      {doorsInfoArray.map((item, index) => (
        <DoorSidePicker door={item} index={index} isSidedCabinet={isSidedCabinet} />
      ))}
    </div>
  );
};

export default MenteseYonu;

const DoorSidePicker = ({ door, index, isSidedCabinet }) => {
  const { doorNumber } = useSelector((state) => state.cabinetMirror);

  const shouldDisplaySideSelector = isSidedCabinet || doorNumber - 1 >= index;

  const { value, label, setter } = door;
  const handleChange = (newValue) => {
    let boolVal;
    boolVal = JSON.parse(newValue);
    setter(boolVal);
  };
  return shouldDisplaySideSelector ? (
    <div className="flex flex-col items-start justify-center w-full gap-2">
      <p className="text-sm text-black">{label}</p>

      <div className="flex flex-row items-center justify-center w-full gap-6">
        <button onClick={() => handleChange(true)} className={`w-32 h-8 text-base rounded-full ${value ? "bg-primary text-secondary" : "bg-secondary text-primary"} flex items-center justify-center`}>
          Left
        </button>

        <button
          onClick={() => handleChange(false)}
          className={`w-32 h-8 text-base rounded-full ${!value ? "bg-primary text-secondary" : "bg-secondary text-primary"} flex items-center justify-center`}
        >
          Right
        </button>
      </div>
    </div>
  ) : null;
};
