import { Modal, Box, Button } from "@mui/material";
import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Content from "./Content";
import InfoIcon from "@mui/icons-material/Info";
import { data } from "./data";
import * as DOMPurify from "dompurify";

const RenderModal = (props) => {
  const { id } = props;
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [title, setTitle] = React.useState();

  useEffect(() => {
    if (props.title) {
      setTitle(props.title);
    } else {
      const titleWithId = data.find((d) => d.id == id)?.title;
      setTitle(titleWithId);
    }
  }, []);

  return (
    <>
      <InfoIcon
        id="icon_ent"
        onClick={handleOpen}
        htmlColor="#0d646f"
        sx={{
          marginRight: "10px",
          cursor: "pointer",
        }}
      />

      {props.children}

      <Modal
        open={open}
        onClose={handleClose}
        sx={{
          "&:focus-visible": {
            backgroundColor: "red !important",
          },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50%",
            height: "80%",
            bgcolor: "background.paper",
            border: "2px solid lightgray",
            borderRadius: 2,
            boxShadow: 24,
            outline: "none !important",
          }}
        >
          <Header>
            <p>{title}</p>
          </Header>

          <Content>
            <div
              dangerouslySetInnerHTML={{
                __html: props.title ? DOMPurify.sanitize(data.find((d) => d.title == title)?.html) : DOMPurify.sanitize(data.find((d) => d.id == id)?.html),
              }}
            ></div>
          </Content>
          <Footer>
            <button
              onClick={() => handleClose()}
              style={{
                borderRadius: 6,
                backgroundColor: "#117A88",
                padding: 10,
                color: "white",
              }}
            >
              Schließen
            </button>
          </Footer>
        </Box>
      </Modal>
    </>
  );
};

export default RenderModal;
