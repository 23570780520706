import { useEffect } from "react";
import AynaliDolapCanvas from "./newComponents/AynaliDolapCanvas";
import { changeMenuIsOpen } from "./store/mirrors";

import { useDispatch, useSelector } from "react-redux";

import { createTheme, ThemeProvider, useMediaQuery } from "@mui/material";

export const App = () => {
  const dispatch = useDispatch();

  const mobileMatches600 = useMediaQuery("(max-width:600px)");

  const { selectedMirror } = useSelector((state) => state.mirror);

  useEffect(() => {
    if (mobileMatches600) {
      dispatch(changeMenuIsOpen(true));
    }
  }, [selectedMirror]);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#0d646f",
        // light: will be calculated from palette.primary.main,
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div id="masterContainerDiv" className="w-full  h-100vh flex">
        <AynaliDolapCanvas />
      </div>
    </ThemeProvider>
  );
};
