import { Canvas } from "@react-three/fiber";
import { Provider, useSelector } from "react-redux";
import store from "../store/index";
import { useMediaQuery } from "@mui/material";
// Room

import Test from "./AynaliDolapComponents/TestCabinet/Test";
import AynalıDolapMenu2 from "./AynaliDolapComponents/AynalıDolapMenu2";
import Panel from "./AynaliDolapComponents/TestCabinet/Panel";
import NewMenu from "./NewMenu/NewMenu";
import { useState } from "react";

const AynaliDolapCanvas = () => {
  const { isMenuOpen } = useSelector((state) => state.mirror);

  const desktopMatches1700 = useMediaQuery("(min-width:1700px)");
  const desktopMatches1200 = useMediaQuery("(min-width:1200px)");
  const mobileMatches600 = useMediaQuery("(min-width:600px)");
  const canvasResponsiveWidth = desktopMatches1700 ? "71vw" : desktopMatches1200 ? "75vw" : mobileMatches600 && isMenuOpen ? "50vw" : "100vw";

  let position = [-9, 0, 0.5];

  const [menuButtonIsActive, setMenuButtonIsActive] = useState(false);
  const [productsMenuButtonIsActive, setProductsMenuButtonIsActive] = useState(false);

  return (
    <>
      <Canvas
        className="canvasHeightEdit"
        style={{
          width: "100vw",
          height: `100vh`,
          float: "right",
        }}
        dpr={[1, 1.5]}
        camera={{ position, fov: 60 }}
        antialias={true}>
        <Provider store={store}>
          <Test />
        </Provider>
      </Canvas>
      {/* <AynalıDolapMenu2 /> */}
      <Panel
        setMenuButtonIsActive={setMenuButtonIsActive}
        setProductsMenuButtonIsActive={setProductsMenuButtonIsActive}
        productsMenuButtonIsActive={productsMenuButtonIsActive}
      />
      <NewMenu
        setMenuButtonIsActive={setMenuButtonIsActive}
        setProductsMenuButtonIsActive={setProductsMenuButtonIsActive}
        menuButtonIsActive={menuButtonIsActive}
      />
    </>
  );
};

export default AynaliDolapCanvas;
