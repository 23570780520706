import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import React, { useEffect, useRef } from "react";

// variation = select || input

const ConfigureSection = ({ handleItemClick, label, index }) => {
  const configureButton = useRef();
  const selectedConfigurator = useRef();

  // useGSAP((context, contextSafe) => {
  //   // gsap.to(".configure-container", {
  //   //   x: "-120%",
  //   //   duration: 1,
  //   //   ease: "expoScale(0.5,7,none)",
  //   // });
  //   // gsap.to(selectedConfigurator.current, {
  //   //   x: "120%",
  //   //   duration: 1.5,
  //   //   ease: "expoScale(0.5,7,none)",
  //   // });
  //   const configureContainer = document.querySelector(".configure-container");
  //   const gapVal = window.getComputedStyle(configureContainer, null).getPropertyValue("gap");
  //   const clickHandler = contextSafe(() => {
  //     console.log(gapVal);
  //     gsap.to(".configure-container", {
  //       x: (-configureContainer.clientWidth - 40) / 2,
  //       duration: 1,
  //       ease: "expoScale(0.5,7,none)",
  //     });
  //     // gsap.to(".configure-section", {
  //     //   x: "-0%",
  //     //   duration: 1.5,
  //     //   ease: "expoScale(0.5,7,none)",
  //     // });
  //   });
  //   configureButton.current.addEventListener("click", clickHandler);
  //   return () => {
  //     configureButton.current.removeEventListener("click", clickHandler);
  //   };
  // });

  return (
    <button
      onClick={handleItemClick}
      className="flex items-center justify-between w-full h-12 !min-h-12 px-6 text-lg font-NeueMontreal tracking-wide rounded-full bg-primary font-medium text-secondary"
    >
      <p>{label}</p>
      <svg width="18" height="14" viewBox="0 0 18 14" fill="none" className="stroke-secondary" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.97455 1L17 7.00002M17 7.00002L8.97455 13M17 7.00002H1" stroke-linecap="square" />
      </svg>
    </button>
  );
};

export default ConfigureSection;
