import { createSlice, current } from "@reduxjs/toolkit";

const initialAnimateState = {
  doorRight: 0,
  doorLeft: 0,
  doorCenter: 0,
  animateDoor: false,
};

const animateSlice = createSlice({
  name: "Animate",
  initialState: initialAnimateState,
  reducers: {
    animateDoor(state, action) {
      if (action.payload == false) {
        state.animateDoor = false;
      } else {
        state.animateDoor = !state.animateDoor;
      }
    },
    openDoorRight(state, action) {
      const { doorRight } = current(state);
      if (doorRight === 2 && action.payload === 2) {
        state.doorRight = 0;
      } else if (doorRight === 1 && action.payload === 1) {
        state.doorRight = 0;
      } else {
        state.doorRight = action.payload;
      }
    },
    openDoorLeft(state, action) {
      const { doorLeft } = current(state);
      if (doorLeft === 2 && action.payload === 2) {
        state.doorLeft = 0;
      } else if (doorLeft === 1 && action.payload === 1) {
        state.doorLeft = 0;
      } else {
        state.doorLeft = action.payload;
      }
    },
    openDoorCenter(state, action) {
      const { doorCenter } = current(state);
      if (doorCenter === 2 && action.payload === 2) {
        state.doorCenter = 0;
      } else if (doorCenter === 1 && action.payload === 1) {
        state.doorCenter = 0;
      } else {
        state.doorCenter = action.payload;
      }
    },
  },
});

export const { animateDoor, openDoorCenter, openDoorLeft, openDoorRight } = animateSlice.actions;

export default animateSlice.reducer;
