import { MeshReflectorMaterial, useGLTF } from "@react-three/drei";
import React from "react";
import * as THREE from "three";
const OxfordMirror = () => {
  const oxfordGeometry = useGLTF("/newModels/oxford.glb").nodes.rightDoor_2.geometry;
  return (
    <>
      <mesh position-z={0.001} geometry={oxfordGeometry}>
        <MeshReflectorMaterial opacity={1} resolution={2048} blur={0} mixBlur={0} mirror={1} />
      </mesh>
      <mesh position-x={0.24} position-z={-0.003} rotation-y={Math.PI}>
        <meshBasicMaterial side={THREE.DoubleSide} color={"#ffffff"} />
        <planeGeometry args={[0.48, 0.78]} />
      </mesh>
    </>
  );
};

export default OxfordMirror;
