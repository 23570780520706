// import { olculer as matrix, urunler as products } from "./data/data";

// async function getProductWithMatrixByName(id, width, height) {
//   const matrixId = products.filter((row) => row.products_id == id)[0].pricematrixes_id;

//   const productPrice = products.filter((row) => row.products_id == id)[0].products_price;

//   const result = matrix.filter((row) => row.pricematrixes_id == matrixId);

//   const price = result.filter((row) => row.x_value === Math.floor(width / 50) * 50 && row.y_value === Math.floor(height / 50) * 50)[0].pricematrixes_values_price;

//   // if (productPrice && price) {

//   // }
//   // return 0;
//   const returnPrice = productPrice + parseFloat(price.toFixed(2));

//   return returnPrice;
// }

// async function getProductWithMatrixByNameForDaire(id, width) {
//   const matrixId = products.filter((row) => row.products_id == id)[0].pricematrixes_id;

//   const productPrice = products.filter((row) => row.products_id == id)[0].products_price;

//   const result = matrix.filter((row) => row.pricematrixes_id == matrixId);

//   const price = result.filter((row) => row.x_value === Math.floor(width / 50) * 50)[0].pricematrixes_values_price;

//   const returnPrice = productPrice + parseFloat(price.toFixed(2));

//   return returnPrice;
// }

// export { getProductWithMatrixByName, getProductWithMatrixByNameForDaire };

import { parseInt } from "lodash";
import { pricematrixes as matrix } from "./data/pricematrixes";
import { products } from "./data/products";
import { EnumMirrors } from "../store/mirrors";

async function getProductWithMatrixByName(id, width, height) {
  const matrixId = products.filter((row) => row.products_id == id)[0].pricematrixes_id;
  const productPrice = products.filter((row) => row.products_id == id)[0].products_price;
  const result = matrix.filter((row) => row.pricematrixes_id == matrixId);

  const price = result.filter((row) => row.x_value === Math.floor(width / 50) * 50 && row.y_value === Math.floor(height / 50) * 50)[0].pricematrixes_values_price;

  const returnPrice = parseFloat(productPrice.replace(",", ".")) + parseFloat(price.toFixed(2));

  return returnPrice;
}

async function getProductWithMatrixByNameForDaire(id, width) {
  const matrixId = products.filter((row) => row.products_id == id)[0].pricematrixes_id;

  const productPrice = products.filter((row) => row.products_id == id)[0].products_price;

  const result = matrix.filter((row) => row.pricematrixes_id == matrixId);

  const price = result.filter((row) => row.x_value === Math.floor(width / 50) * 50)[0].pricematrixes_values_price;

  const returnPrice = parseFloat(productPrice.replace(",", ".")) + parseFloat(price.toFixed(2));

  return returnPrice;
}

async function readPriceFromApiForDaire(products_id, width) {
  //Daire Ayna Genislik id[34]
  const daire = [
    {
      value: "415",
      inputValue: "400",
    },
    {
      value: "416",
      inputValue: "450",
    },
    {
      value: "417",
      inputValue: "500",
    },
    {
      value: "418",
      inputValue: "550",
    },
    {
      value: "419",
      inputValue: "600",
    },
    {
      value: "420",
      inputValue: "650",
    },
    {
      value: "421",
      inputValue: "700",
    },
    {
      value: "422",
      inputValue: "750",
    },
    {
      value: "423",
      inputValue: "800",
    },
    {
      value: "424",
      inputValue: "850",
    },
    {
      value: "425",
      inputValue: "900",
    },
    {
      value: "426",
      inputValue: "950",
    },
    {
      value: "427",
      inputValue: "1000",
    },
    {
      value: "781",
      inputValue: "1050",
    },
    {
      value: "782",
      inputValue: "1100",
    },
    {
      value: "783",
      inputValue: "1150",
    },
    {
      value: "784",
      inputValue: "1200",
    },
    {
      value: "2096",
      inputValue: "1250",
    },
    {
      value: "2097",
      inputValue: "1300",
    },
    {
      value: "2101",
      inputValue: "1400",
    },
    {
      value: "2103",
      inputValue: "1500",
    },
    {
      value: "9554",
      inputValue: "1600",
    },
    {
      value: "9556",
      inputValue: "1700",
    },
    {
      value: "9558",
      inputValue: "1800",
    },
    {
      value: "9560",
      inputValue: "1900",
    },
    {
      value: "9562",
      inputValue: "2000",
    },
  ];

  const daireGenislikValue = {
    id: 34,
    valuesId: daire.find((d) => parseInt(d.inputValue) === width).value,
  };

  let options = [];
  options.push(daireGenislikValue);

  const sendData = JSON.stringify({ productId: products_id, options: options, customerStatus: "1" });

  const data = new URLSearchParams();
  data.append("productData", sendData);

  let returnPrice = 0;
  await fetch("https://www.spiegel21.de/api/pricematrix/priceService.php", {
    method: "POST",
    body: data,
  })
    .then((resp) => resp.json())
    .then((arr) => {
      returnPrice = parseFloat(arr.price.replace(".", "").replace(",", "."));
    });

  return returnPrice;
}

async function readPriceFromApiForKare(products_id, width, height) {
  //id[7]
  const kareGenislik = [
    {
      value: "1803",
      inputValue: "200",
    },
    {
      value: "1804",
      inputValue: "250",
    },
    {
      value: "1805",
      inputValue: "300",
    },
    {
      value: "1806",
      inputValue: "350",
    },
    {
      value: "20",
      inputValue: "400",
    },
    {
      value: "21",
      inputValue: "450",
    },
    {
      value: "22",
      inputValue: "500",
    },
    {
      value: "23",
      inputValue: "550",
    },
    {
      value: "24",
      inputValue: "600",
    },
    {
      value: "25",
      inputValue: "650",
    },
    {
      value: "26",
      inputValue: "700",
    },
    {
      value: "27",
      inputValue: "750",
    },
    {
      value: "28",
      inputValue: "800",
    },
    {
      value: "29",
      inputValue: "850",
    },
    {
      value: "30",
      inputValue: "900",
    },
    {
      value: "31",
      inputValue: "950",
    },
    {
      value: "32",
      inputValue: "1000",
    },
    {
      value: "33",
      inputValue: "1050",
    },
    {
      value: "34",
      inputValue: "1100",
    },
    {
      value: "35",
      inputValue: "1150",
    },
    {
      value: "36",
      inputValue: "1200",
    },
    {
      value: "37",
      inputValue: "1250",
    },
    {
      value: "38",
      inputValue: "1300",
    },
    {
      value: "39",
      inputValue: "1350",
    },
    {
      value: "40",
      inputValue: "1400",
    },
    {
      value: "41",
      inputValue: "1450",
    },
    {
      value: "42",
      inputValue: "1500",
    },
    {
      value: "43",
      inputValue: "1550",
    },
    {
      value: "44",
      inputValue: "1600",
    },
    {
      value: "45",
      inputValue: "1650",
    },
    {
      value: "46",
      inputValue: "1700",
    },
    {
      value: "47",
      inputValue: "1750",
    },
    {
      value: "48",
      inputValue: "1800",
    },
    {
      value: "207",
      inputValue: "1850",
    },
    {
      value: "208",
      inputValue: "1900",
    },
    {
      value: "209",
      inputValue: "1950",
    },
    {
      value: "210",
      inputValue: "2000",
    },
    {
      value: "211",
      inputValue: "2050",
    },
    {
      value: "212",
      inputValue: "2100",
    },
    {
      value: "213",
      inputValue: "2150",
    },
    {
      value: "214",
      inputValue: "2200",
    },
    {
      value: "215",
      inputValue: "2250",
    },
    {
      value: "216",
      inputValue: "2300",
    },
    {
      value: "217",
      inputValue: "2350",
    },
    {
      value: "218",
      inputValue: "2400",
    },
    {
      value: "219",
      inputValue: "2450",
    },
    {
      value: "220",
      inputValue: "2500",
    },
  ];

  //id[8]
  const kareYukseklik = [
    {
      value: "1807",
      inputValue: "200",
    },
    {
      value: "1052",
      inputValue: "250",
    },
    {
      value: "1053",
      inputValue: "300",
    },
    {
      value: "1054",
      inputValue: "350",
    },
    {
      value: "53",
      inputValue: "400",
    },
    {
      value: "54",
      inputValue: "450",
    },
    {
      value: "55",
      inputValue: "500",
    },
    {
      value: "56",
      inputValue: "550",
    },
    {
      value: "57",
      inputValue: "600",
    },
    {
      value: "58",
      inputValue: "650",
    },
    {
      value: "59",
      inputValue: "700",
    },
    {
      value: "60",
      inputValue: "750",
    },
    {
      value: "61",
      inputValue: "800",
    },
    {
      value: "62",
      inputValue: "850",
    },
    {
      value: "63",
      inputValue: "900",
    },
    {
      value: "64",
      inputValue: "950",
    },
    {
      value: "65",
      inputValue: "1000",
    },
    {
      value: "66",
      inputValue: "1050",
    },
    {
      value: "67",
      inputValue: "1100",
    },
    {
      value: "68",
      inputValue: "1150",
    },
    {
      value: "69",
      inputValue: "1200",
    },
    {
      value: "70",
      inputValue: "1250",
    },
    {
      value: "71",
      inputValue: "1300",
    },
    {
      value: "72",
      inputValue: "1350",
    },
    {
      value: "73",
      inputValue: "1400",
    },
    {
      value: "74",
      inputValue: "1450",
    },
    {
      value: "75",
      inputValue: "1500",
    },
    {
      value: "76",
      inputValue: "1550",
    },
    {
      value: "77",
      inputValue: "1600",
    },
    {
      value: "78",
      inputValue: "1650",
    },
    {
      value: "79",
      inputValue: "1700",
    },
    {
      value: "80",
      inputValue: "1750",
    },
    {
      value: "81",
      inputValue: "1800",
    },
    {
      value: "221",
      inputValue: "1850",
    },
    {
      value: "222",
      inputValue: "1900",
    },
    {
      value: "223",
      inputValue: "1950",
    },
    {
      value: "224",
      inputValue: "2000",
    },
    {
      value: "225",
      inputValue: "2050",
    },
    {
      value: "226",
      inputValue: "2100",
    },
    {
      value: "227",
      inputValue: "2150",
    },
    {
      value: "228",
      inputValue: "2200",
    },
    {
      value: "229",
      inputValue: "2250",
    },
    {
      value: "230",
      inputValue: "2300",
    },
    {
      value: "231",
      inputValue: "2350",
    },
    {
      value: "232",
      inputValue: "2400",
    },
    {
      value: "233",
      inputValue: "2450",
    },
    {
      value: "234",
      inputValue: "2500",
    },
  ];

  const kareGenislikValue = {
    id: 7,
    valuesId: kareGenislik.find((k) => parseInt(k.inputValue) === width).value,
  };

  const kareYukskelikValue = {
    id: 8,
    valuesId: kareYukseklik.find((k) => parseInt(k.inputValue) === height).value,
  };

  let options = [];
  options.push(kareGenislikValue);
  options.push(kareYukskelikValue);

  const sendData = JSON.stringify({ productId: products_id, options: options, customerStatus: "1" });

  const data = new URLSearchParams();
  data.append("productData", sendData);

  let returnPrice = 0;
  await fetch("https://www.spiegel21.de/api/pricematrix/priceService.php", {
    method: "POST",
    body: data,
  })
    .then((resp) => resp.json())
    .then((arr) => {
      returnPrice = parseFloat(arr.price.replace(".", "").replace(",", "."));
    });

  return returnPrice;
}

async function readPriceFromApiForOval(products_id, width, height) {
  //id[164]
  const ovalGenislik = [
    { value: "1818", inputValue: "400" },
    { value: "1819", inputValue: "450" },
    { value: "1820", inputValue: "500" },
    { value: "1821", inputValue: "550" },
    { value: "1822", inputValue: "600" },
    { value: "1823", inputValue: "650" },
    { value: "1824", inputValue: "700" },
    { value: "1825", inputValue: "750" },
    { value: "1826", inputValue: "800" },
    { value: "1827", inputValue: "850" },
    { value: "1828", inputValue: "900" },
    { value: "1829", inputValue: "950" },
    { value: "1830", inputValue: "1000" },
    { value: "1831", inputValue: "1050" },
    { value: "1832", inputValue: "1100" },
    { value: "1833", inputValue: "1150" },
    { value: "1834", inputValue: "1200" },
    { value: "1835", inputValue: "1250" },
    { value: "1836", inputValue: "1300" },
    { value: "1837", inputValue: "1350" },
    { value: "1838", inputValue: "1400" },
    { value: "1839", inputValue: "1450" },
    { value: "1840", inputValue: "1500" },
    { value: "1841", inputValue: "1550" },
    { value: "1842", inputValue: "1600" },
    { value: "1843", inputValue: "1650" },
    { value: "1844", inputValue: "1700" },
    { value: "1845", inputValue: "1750" },
    { value: "1846", inputValue: "1800" },
    { value: "1847", inputValue: "1850" },
    { value: "1848", inputValue: "1900" },
    { value: "1849", inputValue: "1950" },
    { value: "1850", inputValue: "2000" },
    { value: "1851", inputValue: "2050" },
    { value: "1852", inputValue: "2100" },
    { value: "1853", inputValue: "2150" },
    { value: "1854", inputValue: "2200" },
    { value: "1855", inputValue: "2250" },
    { value: "1856", inputValue: "2300" },
    { value: "1857", inputValue: "2350" },
    { value: "1858", inputValue: "2400" },
    { value: "1859", inputValue: "2450" },
    { value: "1860", inputValue: "2500" },
  ];

  //id[165]
  const ovalYukseklik = [
    { value: "1865", inputValue: "400" },
    { value: "1866", inputValue: "450" },
    { value: "1867", inputValue: "500" },
    { value: "1868", inputValue: "550" },
    { value: "1869", inputValue: "600" },
    { value: "1870", inputValue: "650" },
    { value: "1871", inputValue: "700" },
    { value: "1872", inputValue: "750" },
    { value: "1873", inputValue: "800" },
    { value: "1874", inputValue: "850" },
    { value: "1875", inputValue: "900" },
    { value: "1876", inputValue: "950" },
    { value: "1877", inputValue: "1000" },
    { value: "1878", inputValue: "1050" },
    { value: "1879", inputValue: "1100" },
    { value: "1880", inputValue: "1150" },
    { value: "1881", inputValue: "1200" },
    { value: "1882", inputValue: "1250" },
    { value: "1883", inputValue: "1300" },
    { value: "1884", inputValue: "1350" },
    { value: "1885", inputValue: "1400" },
    { value: "1886", inputValue: "1450" },
    { value: "1887", inputValue: "1500" },
    { value: "1888", inputValue: "1550" },
    { value: "1889", inputValue: "1600" },
    { value: "1890", inputValue: "1650" },
    { value: "1891", inputValue: "1700" },
    { value: "1892", inputValue: "1750" },
    { value: "1893", inputValue: "1800" },
    { value: "1894", inputValue: "1850" },
    { value: "1895", inputValue: "1900" },
    { value: "1896", inputValue: "1950" },
    { value: "1897", inputValue: "2000" },
    { value: "1898", inputValue: "2050" },
    { value: "1899", inputValue: "2100" },
    { value: "1900", inputValue: "2150" },
    { value: "1901", inputValue: "2200" },
    { value: "1902", inputValue: "2250" },
    { value: "1903", inputValue: "2300" },
    { value: "1904", inputValue: "2350" },
    { value: "1905", inputValue: "2400" },
    { value: "1906", inputValue: "2450" },
    { value: "1907", inputValue: "2500" },
  ];

  const ovalGenislikValue = {
    id: 164,
    valuesId: ovalGenislik.find((o) => parseInt(o.inputValue) === width).value,
  };

  const ovalYukskelikValue = {
    id: 165,
    valuesId: ovalYukseklik.find((o) => parseInt(o.inputValue) === height).value,
  };

  let options = [];
  options.push(ovalGenislikValue);
  options.push(ovalYukskelikValue);

  const sendData = JSON.stringify({ productId: products_id, options: options, customerStatus: "1" });

  const data = new URLSearchParams();
  data.append("productData", sendData);

  let returnPrice = 0;
  await fetch("https://www.spiegel21.de/api/pricematrix/priceService.php", {
    method: "POST",
    body: data,
  })
    .then((resp) => resp.json())
    .then((arr) => {
      returnPrice = parseFloat(arr.price.replace(".", "").replace(",", "."));
    });

  return returnPrice;
}

export { getProductWithMatrixByName, getProductWithMatrixByNameForDaire, readPriceFromApiForDaire, readPriceFromApiForKare, readPriceFromApiForOval };
