import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { setDoorHandle } from "../../../store/cabinetMirror";
import { setDoorHandleFiyatı } from "../../../store/cabinetMirrorFee";

const KapıKolu = () => {
  const dispatch = useDispatch();
  const { doorHandle } = useSelector((state) => state.cabinetMirror);
  const { selectedMirror } = useSelector((state) => state.mirror);

  const handleChange = (value) => {
    dispatch(setDoorHandle(value));
    dispatch(setDoorHandleFiyatı(value === 2 ? 39.99 : value === 5 ? 14.95 : value === 6 ? 29.95 : value === 1 ? 42.45 : value === 4 ? 64.95 : value === 3 ? 19.99 : value == 99 ? 39.99 : 0));
  };

  useEffect(() => {
    dispatch(setDoorHandle(0));
    dispatch(setDoorHandleFiyatı(0));
  }, [selectedMirror]);

  const optionsArray = [
    { value: 0, label: "Without opening mechanism" },
    { value: 99, label: "Push-to-Open + 39,99 €" },
    { value: 5, label: "Mango + 14,95 €" },
    { value: 3, label: "Fina + 19,99 €" },
    { value: 6, label: "Moda + 29,95 €" },
    { value: 1, label: "Canto + 42,45 €" },
    { value: 4, label: "Presa + 64,95 €" },
  ];

  return (
    <div className="flex flex-col items-center w-full gap-2">
      {optionsArray.map((option, index) => (
        <button
          onClick={() => handleChange(option.value)}
          className={`w-full h-9 text-base rounded-full ${doorHandle === option.value ? "bg-primary text-secondary" : "bg-secondary text-primary"} flex items-center justify-center`}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
};

export default KapıKolu;
