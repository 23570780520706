import { createSlice, current } from "@reduxjs/toolkit";
export const EnumCabinetMirrors = {
  OXFORD: 2320, // STANDARD
  FRANKFURT: 3080, // STANDARD
  DRESDEN: 367, // STANDARD
  MUNCHEN_4: 3148, // SIDED
  MUNCHEN_5: 3145, // CENTERED
  LAS_VEGAS: 878, // CENTERED
  SUPREME: 10, // STANDARD
  BUDAPEST: 1986, // STANDARD_BOTTOM_SHELVES
  CHICAGO: 359, // STANDARD
  COLUMBUS: 1673, // STANDARD
  DUBLIN: 369, // STANDARD
  HAMBURG: 2098, // STANDARD
  MUNCHEN_6: 6300, // STANDARD_BOTTOM_SHELVES
  MUNCHEN_1: 2095, // STANDARD
  BRISTOL: 2479, // STANDARD
  SARAGOSSA: 2419, // SIDED
  MALAGA: 2424, // SIDED
  EL_PASO: 370, // STANDARD
  HAVEL: 8297, // STANDARD                            ALUMINUM
  SANTANDER: 1981, // STANDARD_BOTTOM_SHELVES
  KONSTANZ: 2416, // SIDED
  LECH: 8306, // STANDARD                             ALUMINUM
  GENT: 2321, // SIDED_BOTTOM_SHELVES
  VENEZIA: 2454, // SIDED_BOTTOM_SHELVES
  DERBY: 2323, // SIDED
  WESER: 8264, // STANDARD                            ALUMINUM
  ELBE_1: 8255, // STANDARD                            ALUMINUM
  NECKAR: 8291, // STANDARD                            ALUMINUM
  LIPPE: 8309, // STANDARD                            ALUMINUM
  WERRA: 8312, // STANDARD
  LISSABON: 1979, // STANDARD_BOTTOM_SHELVES
  LINZ: 1985, // STANDARD_BOTTOM_SHELVES
  VILLACH: 2423, // SIDED
  CORTINA: 2453, // STANDARD_BOTTOM_SHELVES
  ALMERIA: 2417, // SIDED
  GRADO: 2452, // SIDED
  WINDSOR: 2556, // STANDARD_BOTTOM_SHELVES
  VERONICA_1: 2562, // NEW AND PANDORA (STANDARD)
  VERONICA_2: 2560, // NEW AND PANDORA (BOTTOM)
  VERONICA_3: 2558, // NEW AND PANDORA (SIDES)
  VERONICA_4: 2566, // NEW AND PANDORA (SIDES + BOTTOM)
  PANDORA_1: 2557, // NEW AND PANDORA (STANDARD)
  PANDORA_2: 2568, // NEW AND PANDORA (BOTTOM)
  PANDORA_3: 2554, // NEW AND PANDORA (SIDES)
  PANDORA_4: 2559, // NEW AND PANDORA (SIDES + BOTTOM)
  KATHERINE_1: 2553, // NEW AND PANDORA (STANDARD)
  KATHERINE_2: 2570, // NEW AND PANDORA (BOTTOM)
  KATHERINE_3: 2575, // NEW AND PANDORA (SIDES)
  KATHERINE_4: 2576, // NEW AND PANDORA (SIDES + BOTTOM)
};

let initialMirrorState = {
  cabinetMirrorDepth: 125,
  cabinetMirrorWidth: 1950,
  cabinetMirrorHeight: 1400,
  cabinetMirrorType: -0.08,
  borderWidth: 100,
  borderHeight: 0,
  textureIndex: 0, // bu silinecek değiştirdikten sonra
  //Menü için sonradan eklenenler\\
  lightColor: [100, 100, 100],
  lightColorIndex: 0,
  lavaboTavanLightSalter: 1342,
  lavaboTavanLightColorIndex: 0,
  lightIsOpen: 0,
  bodyDecor: "H1487_ST22.jpeg",
  backPanel: 0,
  externalLeds: 0,
  bluetoothSpeaker: false,
  hasMakeupMirror: false,
  socketUsb: "754",
  socketUsbDisarida: 0,
  summaryModalOpen: false,
  orderAmount: 1,
  customerComment: "",
  doorHandle: 0,
  doorHandlePos: 0,
  camCikintiUst: 1,
  camCikintiAlt: 1,
  ahsapZemin: 0,
  draggerVisible: 0,
  sensor: "250",
  doorNumber: 3,
  shelfCount: 0,
  selectedMirrorName: "Bad Spiegelschrank nach Maß - OXFORD",
  selectedMirrorExcelIndex: 2320,
  doorOpeningSide: "left",
  lasvegasFirstLeftSided: true,
  lasvegasSecondLeftSided: false,
  isFirstLeftSided: false,
  isSecondLeftSided: false,
  isThirdLeftSided: false,
  isFourthLeftSided: false,
  isFifthLeftSided: false,
  isSixthLeftSided: false,
  openAllDoors: false,
  pandoraLight: "",
  pandoraLightScaleX: 1,
  widthCmVisible: false,
  heightCmVisible: false,
  depthCmVisible: false,
  customDoorWidthValues: [],
  montajCevresiDerinligi: 70,
  kurulumCercevesi: false,
  bottomCompartmentHeight: 100,
};

const cabinetMirror = createSlice({
  name: "CabinetMirror",
  initialState: initialMirrorState,
  reducers: {
    setCustomDoorWidthValues(state, action) {
      state.customDoorWidthValues = action.payload;
    },

    changeKurulumCercevesi(state, action) {
      state.kurulumCercevesi = action.payload;
    },

    setMontajCevresiDerinligi(state, action) {
      state.montajCevresiDerinligi = action.payload;
    },

    setIsFirstLeftSided(state, action) {
      state.isFirstLeftSided = action.payload;
    },
    setIsSecondLeftSided(state, action) {
      state.isSecondLeftSided = action.payload;
    },
    setIsThirdLeftSided(state, action) {
      state.isThirdLeftSided = action.payload;
    },
    setIsFourthLeftSided(state, action) {
      state.isFourthLeftSided = action.payload;
    },
    setIsFifthLeftSided(state, action) {
      state.isFifthLeftSided = action.payload;
    },
    setIsSixthLeftSided(state, action) {
      state.isSixthLeftSided = action.payload;
    },
    setPandoraLight(state, action) {
      state.pandoraLight = action.payload;
    },
    setPandoraLightScaleX(state, action) {
      state.pandoraLightScaleX = action.payload;
    },
    setLasvegasLeftOpeningSide(state, action) {
      state.lasvegasFirstLeftSided = action.payload;
    },
    setLasvegasRightOpeningSide(state, action) {
      state.lasvegasSecondLeftSided = action.payload;
    },
    changeCabinetMirrorWidth(state, action) {
      state.cabinetMirrorWidth = action.payload;
    },
    changeSelectedExcelItem(state, action) {
      const { id, name } = action.payload;
      state.selectedMirrorName = name;
      state.selectedMirrorExcelIndex = id;
    },
    changeCabinetMirrorHeight(state, action) {
      state.cabinetMirrorHeight = action.payload;
    },
    changeCabinetMirrorType(state, action) {
      state.cabinetMirrorType = action.payload;
    },
    changeCabinetMirrorDepth(state, action) {
      state.cabinetMirrorDepth = action.payload;
    },
    changeBorderWidth(state, action) {
      state.borderWidth = action.payload;
    },
    changeBorderHeight(state, action) {
      state.borderHeight = action.payload;
    },
    changeTextureIndex(state, action) {
      state.textureIndex = action.payload;
    },
    setTextureIndex: (state, action) => {
      state.textureIndex = action.payload;
    }, //// Bunu aşağıdaki bodyDecor ile değiştirmemiz gerekiyor

    ////MENU ICIN SONRADAN EKLENENLER\\\\
    setLightColor: (state, action) => {
      state.lightColor = action.payload;
    },
    setBodyDecor: (state, action) => {
      state.bodyDecor = action.payload;
    },
    setBackPanel: (state, action) => {
      state.backPanel = action.payload;
    },
    setExternalLeds: (state, action) => {
      state.externalLeds = action.payload;
    },
    setBluetoothSpeaker: (state, action) => {
      state.bluetoothSpeaker = action.payload;
    },
    setHasMakeupMirror: (state, action) => {
      state.hasMakeupMirror = action.payload;
    },
    setSocketUsb: (state, action) => {
      state.socketUsb = action.payload;
    },
    setSocketUsbDisarida: (state, action) => {
      state.socketUsbDisarida = action.payload;
    },
    setSummaryModalOpen: (state, action) => {
      state.summaryModalOpen = action.payload;
    },
    setOrderAmount: (state, action) => {
      state.orderAmount = action.payload;
    },
    setCustomerComment: (state, action) => {
      state.customerComment = action.payload;
    },
    setDoorHandle: (state, action) => {
      state.doorHandle = action.payload;
    },

    setDoorHandlePos: (state, action) => {
      state.doorHandlePos = action.payload;
    },
    setCamCikintiUst: (state, action) => {
      state.camCikintiUst = action.payload;
    },
    setCamCikintiAlt: (state, action) => {
      state.camCikintiAlt = action.payload;
    },
    setAhsapZemin: (state, action) => {
      state.ahsapZemin = action.payload;
    },
    setSensor: (state, action) => {
      state.sensor = action.payload;
    },
    setDraggerVisible: (state, action) => {
      state.draggerVisible = action.payload;
    },
    //--
    setDoorNumber: (state, action) => {
      state.doorNumber = action.payload;
    },
    setShelfCount: (state, action) => {
      state.shelfCount = action.payload;
    },
    setDoorOpeningSide: (state, action) => {
      state.doorOpeningSide = action.payload;
    },
    changeLightStatus(state, action) {
      if (action.payload == false) {
        state.lightIsOpen = false;
      } else {
        state.lightIsOpen = !state.lightIsOpen;
      }
    },
    changeLightColor(state, action) {
      if (action.payload === 0) {
        // WHITE
        state.lightColor = [100, 100, 100];
        state.lightColorIndex = 0;
      } else if (action.payload === 1) {
        // SICAK BEYAZ
        state.lightColor = [100, 60, 1];
        state.lightColorIndex = 1;
      } else if (action.payload === 2) {
        // BLUE
        state.lightColor = [20, 50, 100];
        state.lightColorIndex = 2;
      } else if (action.payload === 3) {
        // RED
        // state.lightColor = [1000, 0, -20];
        state.lightColorIndex = 3;
      } else if (action.payload === 4) {
        // YELLOW
        // state.lightColor = [2, 100, -100];
        state.lightColorIndex = 4;
      } else if (action.payload === 5) {
        // PINK
        // state.lightColor = [100, 0.1, 1];
        state.lightColorIndex = 5;
      }
    },
    setOpenAllDoors: (state, action) => {
      state.openAllDoors = !state.openAllDoors;
    },
    changeLavaboTavanLightColor: (state, action) => {
      state.lavaboTavanLightColorIndex = action.payload;
    },
    changeLavaboTavanLightSalter: (state, action) => {
      state.lavaboTavanLightSalter = action.payload;
    },

    changeHeightCmVisible: (state, action) => {
      state.heightCmVisible = !state.heightCmVisible;
    },
    changeWidthCmVisible: (state, action) => {
      state.widthCmVisible = !state.widthCmVisible;
    },
    changeDepthCmVisible: (state, action) => {
      state.depthCmVisible = !state.depthCmVisible;
    },
    setBottomCompartmentHeight: (state, action) => {
      state.bottomCompartmentHeight = action.payload;
    },
    resetToInitialState: (state) => {
      state.bottomCompartmentHeight = 100;
      state.cabinetMirrorDepth = 0;
      state.cabinetMirrorWidth = 0;
      state.cabinetMirrorHeight = 0;
      state.cabinetMirrorType = -0.15;
      state.borderWidth = 100;
      state.borderHeight = 0;
      state.textureIndex = 0;
      state.lightColor = 0;
      state.lightColorIndex = 0;
      state.lightIsOpen = false;
      state.bodyDecor = 0;
      state.backPanel = 0;
      state.externalLeds = 0;
      state.bluetoothSpeaker = false;
      state.hasMakeupMirror = false;
      state.socketUsb = "default";
      state.socketUsbDisarida = "default";
      state.summaryModalOpen = false;
      state.orderAmount = 1;
      state.customerComment = "";
      state.doorHandle = 0;
      state.doorHandlePos = 0;
      state.camCikintiUst = 1;
      state.camCikintiAlt = 1;
      state.ahsapZemin = 0;
      state.draggerVisible = 0;
      state.sensor = "default";
      state.doorNumber = 1;
      state.montajCevresiDerinligi = 70;
      state.openAllDoors = false;
    },
  },
});

export let {
  setBottomCompartmentHeight,
  setCustomDoorWidthValues,
  setIsFirstLeftSided,
  setIsSecondLeftSided,
  setIsThirdLeftSided,
  setIsFourthLeftSided,
  setIsFifthLeftSided,
  setIsSixthLeftSided,
  setLasvegasLeftOpeningSide,
  setLasvegasRightOpeningSide,
  changeCabinetMirrorDepth,
  changeCabinetMirrorHeight,
  changeCabinetMirrorWidth,
  changeCabinetMirrorType,
  changeBorderWidth,
  changeBorderHeight,
  changeTextureIndex,
  disableMirror,
  setLightColor,
  setBodyDecor,
  setBackPanel,
  setPandoraLight,
  setPandoraLightScaleX,
  setExternalLeds,
  setBluetoothSpeaker,
  setHasMakeupMirror,
  setSocketUsb,
  setSocketUsbDisarida,
  setSummaryModalOpen,
  increaseOrderAmount,
  decreaseOrderAmount,
  setOrderAmount,
  setCustomerComment,
  resetToInitialState,
  setDoorHandle,
  setDoorHandlePos,
  setCamCikintiAlt,
  setCamCikintiUst,
  changeKurulumCercevesi,
  setAhsapZemin,
  setSensor,
  setShelfCount,
  setDraggerVisible,
  changeLightColor,
  changeLightStatus,
  changeSelectedExcelItem,
  setDoorOpeningSide,
  setOpenAllDoors,
  changeHeightCmVisible,
  changeWidthCmVisible,
  changeDepthCmVisible,
  changeLavaboTavanLightColor,
  changeLavaboTavanLightSalter,
  //--
  setDoorNumber,
  setMontajCevresiDerinligi,
} = cabinetMirror.actions;

export default cabinetMirror.reducer;
