import React, { useEffect, useState } from "react";
import ConfigureSection from "../Common/ConfigureSection";

import gsap from "gsap";

import { KapıKolu, MenteseYonu, LavaboTavan, LedIsıgı, Kapılar, SensorAnahtar, Genislik, Yukseklik, Derinlik, GovdeDoku, CamRaflar, AhsapZeminler, AynaAdedi } from "../options";
import Button from "../Common/Button";

const ConfigureContainer = ({ itemClicked, setItemClicked }) => {
  const [selectedMenuItemIndex, setSelectedMenuItemIndex] = useState(0);

  const menuOptions = [
    { label: "Width" },
    { label: "Height" },
    { label: "Depth" },
    { label: "Door Number" },
    { label: "Led Light" },
    { label: "Body Decor" },
    { label: "Openin Sides" },
    { label: "Door Handles" },
    { label: "Glass Shelves" },
    { label: "Wooden Panels" },
    { label: "Sensor - Socket" },
    { label: "Ceiling - Venicity Lights" },
    { label: "Order Amount" },
  ];

  const selectedItem = () => {
    switch (selectedMenuItemIndex) {
      case 0:
        return <Genislik />;
      case 1:
        return <Yukseklik />;
      case 2:
        return <Derinlik />;
      case 3:
        return <Kapılar />;
      case 4:
        return <LedIsıgı />;
      case 5:
        return <GovdeDoku />;
      case 6:
        return <MenteseYonu />;
      case 7:
        return <KapıKolu />;
      case 8:
        return <CamRaflar />;
      case 9:
        return <AhsapZeminler />;
      case 10:
        return <SensorAnahtar />;
      case 11:
        return <LavaboTavan />;
      case 12:
        return <AynaAdedi />;
      default:
        return null;
    }
  };

  const configuratorContainer = document.getElementById("configuratorContainer");

  const handleItemClick = (index) => {
    setSelectedMenuItemIndex(index);
    setItemClicked(true);
    console.log(index);
  };
  const handleDoneClick = (index) => {
    setItemClicked(false);
  };

  useEffect(() => {
    if (configuratorContainer) {
      gsap.to(configuratorContainer, {
        x: itemClicked ? -20 * 16 : 0,
        duration: 0.3,
      });
    }
  }, [itemClicked, selectedMenuItemIndex]);

  return (
    <div className="flex flex-col w-full gap-5 px-4 overflow-hidden !font-NeueMontreal grow">
      <div id="configuratorContainer" className="w-[38rem] h-full gap-10 configure-container overflow-hidden flex flex-row items-start justify-between">
        <div className="flex flex-col w-2/4 h-full pr-3 overflow-y-auto">
          <div className="flex flex-col w-full gap-5 configureSection">
            {menuOptions.map((option, index) => (
              <ConfigureSection index={index} handleItemClick={() => handleItemClick(index)} label={option.label} />
            ))}
          </div>
        </div>
        <div className="flex flex-col items-center w-2/4 h-full gap-4 overflow-y-auto">
          <h4 className="mb-4 text-2xl mr-[10px]  text-secondary">{menuOptions[selectedMenuItemIndex].label}</h4>
          {selectedItem()}
        </div>
      </div>
    </div>
  );
};

export default ConfigureContainer;
