import { configureStore } from "@reduxjs/toolkit";
import animateReducer from "./animate";
import mirrorReducer from "./mirrors";
import cabinetMirrorReducer from "./cabinetMirror";
import cabinetReducer from "./cabinet";
import utilsReducer from "./utils";
import cabinetMirrorFeeReducer from "./cabinetMirrorFee";
import cabinetFeeReducer from "./cabinetFee";
import mirrorFeeReducer from "./mirrorFee";

const store = configureStore({
  reducer: {
    animate: animateReducer,
    mirror: mirrorReducer,
    cabinet: cabinetReducer,
    utils: utilsReducer,
    cabinetMirror: cabinetMirrorReducer,
    cabinetMirrorFee: cabinetMirrorFeeReducer,
    cabinetFee: cabinetFeeReducer,
    mirrorFee: mirrorFeeReducer,
  },
});

export default store;
