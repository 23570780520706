import React, { useEffect } from "react";
import { Stack, Typography, Select, MenuItem } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { EnumCabinetMirrors, setSensor, setSocketUsb } from "../../../store/cabinetMirror";
import { setSensorFiyatı, setSocketUsbFiyatı } from "../../../store/cabinetMirrorFee";

const SensorAnahtar = () => {
  const dispatch = useDispatch();
  const { sensor } = useSelector((state) => state.cabinetMirror);
  const { selectedMirror } = useSelector((state) => state.mirror);
  const handleChange = (e) => {
    dispatch(setSensor(e.target.value));
    dispatch(setSocketUsb("754"));
    dispatch(setSocketUsbFiyatı(0));
  };

  const sensorSwitchOptions = [
    {
      value: "250",
      label: "---",
      price: 0,
    },
    {
      value: "6789",
      label: "Switch left",
      price: 39,
    },
    {
      value: "6790",
      label: "Switch right",
      price: 39,
    },
    {
      value: "6791",
      label: "Dimmer left",
      price: 49,
    },
    {
      value: "6792",
      label: "Dimmer right",
      price: 49,
    },

    {
      value: "251",
      label: "Switch + Socket left ",
      price: 49,
    },
    {
      value: "578",
      label: "Switch + Socket right ",
      price: 49,
    },
    {
      value: "6785",
      label: "Switch + 2x Socket left",
      price: 89,
    },
    {
      value: "6786",
      label: "Switch + 2x Socket right",
      price: 89,
    },
    {
      value: "6787",
      label: "Switch + Socket + USB left",
      price: 119,
    },
    {
      value: "6788",
      label: "Switch + Socket + USB right",
      price: 119,
    },
    {
      value: "6805",
      label: "Dimmer + Socket left",
      price: 85,
    },
    {
      value: "6806",
      label: "Dimmer + Socket right",
      price: 85,
    },
    {
      value: "6807",
      label: "Dimmer + 2x Socket left",
      price: 99,
    },
    {
      value: "6808",
      label: "Dimmer + 2x Socket right",
      price: 99,
    },
    {
      value: "6809",
      label: "Dimmer + Socket + USB left",
      price: 129,
    },
    {
      value: "6810",
      label: "Dimmer + Socket + USB right",
      price: 129,
    },
  ];

  useEffect(() => {
    const matchedOption = sensorSwitchOptions.find((item) => item.value === sensor);
    if (matchedOption) {
      dispatch(setSensorFiyatı(matchedOption.price));
      dispatch(setSensor(matchedOption.value));
    }
  }, [sensor]);

  useEffect(() => {
    dispatch(setSensorFiyatı(0));
    dispatch(setSensor("250"));
  }, [selectedMirror]);

  return (
    <div className="flex flex-col items-center w-full gap-2">
      {sensorSwitchOptions.map((option, index) => (
        <button
          onClick={() => handleChange(option.value)}
          className={`w-full h-9 text-base rounded-full ${sensor === option.value ? "bg-primary text-secondary" : "bg-secondary text-primary"} flex items-center justify-center`}
        >
          {option.label} {option.price > 0 ? "+ €" + option.price : ""}
        </button>
      ))}
    </div>
  );
};

export default SensorAnahtar;
