import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { setDoorNumber } from "../../../store/cabinetMirror";
import { setDoorFiyatı } from "../../../store/cabinetMirrorFee";

const Kapılar = () => {
  const dispatch = useDispatch();
  const { selectedMirror } = useSelector((state) => state.mirror);
  const { doorNumber, cabinetMirrorWidth } = useSelector((state) => state.cabinetMirror);

  const handleChange = (value) => {
    dispatch(setDoorNumber(value));
    dispatch(setDoorFiyatı(value === 2 ? 69.99 : value === 3 ? 119.99 : value === 4 ? 159.99 : value === 5 ? 189.99 : value === 6 ? 209.99 : 0));
  };

  useEffect(() => {
    if (cabinetMirrorWidth > 900) {
      dispatch(setDoorNumber(3));
      dispatch(setDoorFiyatı(119.99));
    } else if (cabinetMirrorWidth > 650) {
      dispatch(setDoorNumber(2));
      dispatch(setDoorFiyatı(69.99));
    } else {
      dispatch(setDoorNumber(1));
      dispatch(setDoorFiyatı(0));
    }
  }, [cabinetMirrorWidth, selectedMirror]);

  useEffect(() => {
    dispatch(setDoorNumber(3));
    dispatch(setDoorFiyatı(119.99));
  }, [selectedMirror]);

  const optionsArray = [
    {
      value: 1,
      text: "1 Door (300mm-650mm)",
      disabled: cabinetMirrorWidth > 650,
    },
    {
      value: 2,
      text: "2 Doors (600mm-1300mm) + 69,99 €",
      disabled: cabinetMirrorWidth < 600 || cabinetMirrorWidth > 1300,
    },
    {
      value: 3,
      text: "3 Doors (900mm-1950mm) + 119,99 €",
      disabled: cabinetMirrorWidth < 900 || cabinetMirrorWidth > 1950,
    },
  ];

  const shouldDisableOptions = (value) => {
    switch (value) {
      case 1:
        return cabinetMirrorWidth > 650;
      case 2:
        return cabinetMirrorWidth < 600 || cabinetMirrorWidth > 1300;
      case 3:
        return cabinetMirrorWidth < 900 || cabinetMirrorWidth > 1950;
    }
  };

  return (
    <div className="flex flex-col items-center w-full gap-2">
      {optionsArray.map((option, index) => (
        <button
          disabled={shouldDisableOptions(option.value)}
          onClick={() => handleChange(option.value)}
          className={`w-full h-9 text-base rounded-full ${doorNumber === option.value ? "bg-primary text-secondary" : "bg-secondary text-primary"} flex items-center justify-center`}
        >
          {option.text}
        </button>
      ))}
    </div>
  );
};

export default Kapılar;
