import React, { forwardRef } from "react";

const DışAydınlatma = forwardRef(({ position, rotation, scaleY }, ref) => (
  <group ref={ref} position={position} rotation={rotation}>
    <mesh position-x={0.007}>
      <boxGeometry args={[0.003, scaleY, 0.001]} />
      <meshBasicMaterial color={"#999999"} />
    </mesh>
    <mesh position-x={-0.007}>
      <boxGeometry args={[0.003, scaleY, 0.001]} />
      <meshBasicMaterial color={"#999999"} />
    </mesh>
    <mesh>
      <boxGeometry args={[0.01, scaleY, 0.001]} />
      <meshStandardMaterial toneMapped={false} color={[100, 100, 100]} />
    </mesh>
  </group>
));

export default DışAydınlatma;
