import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  cabinetMirrorName: "Oxford",
  baseFee: 0,
  ahsapZeminFiyatı: 0,
  backPanelFiyatı: 0,
  bluetoothSpeakerFiyatı: 0,
  camRafFiyatı: 0,
  cabinetMirrorTypeFiyatı: 0,
  doorHandleFiyatı: 0,
  doorFiyatı: 0,
  externalLedFiyatı: 0,
  makeupMirrorFiyatı: 0,
  sensorFiyatı: 0,
  ledIsikFiyatı: 0,
  dekorFiyati: 0,
  derinlikFee: 0,
  pandoraLightFee: 0,
  socketUsbFee: 0,
  socketUsbDisaridaFee: 0,
  lavaboTavanLightSalterFiyatı: 0,
  kurulumCercevesiFee:0,
};

const cabinetMirrorFee = createSlice({
  name: "cabinetMirrorFee",
  initialState: initialState,
  reducers: {
    setBaseFee(state, action) {
      state.baseFee = action.payload;
    },
    setKurulumCercevesiFee(state, action) {
      state.kurulumCercevesiFee = action.payload;
    },
    setAhsapZeminFiyatı(state, action) {
      state.ahsapZeminFiyatı = action.payload;
    },
    setLavaboTavanLightSalterFiyatı(state, action) {
      state.lavaboTavanLightSalterFiyatı = action.payload;
    },
    setDekorFiyatı(state, action) {
      state.dekorFiyati = action.payload;
    },
    setBackPanelFiyatı(state, action) {
      state.backPanelFiyatı = action.payload;
    },
    setBluetoothSpeakerFiyatı(state, action) {
      state.bluetoothSpeakerFiyatı = action.payload;
    },
    setCamRafFiyatı(state, action) {
      state.camRafFiyatı = action.payload;
    },
    setCabinetMirrorTypeFiyatı(state, action) {
      state.cabinetMirrorTypeFiyatı = action.payload;
    },
    setDoorHandleFiyatı(state, action) {
      state.doorHandleFiyatı = action.payload;
    },
    setDoorFiyatı(state, action) {
      state.doorFiyatı = action.payload;
    },
    setExternalLedFiyatı(state, action) {
      state.externalLedFiyatı = action.payload;
    },
    setMakeupMirrorFiyatı(state, action) {
      state.makeupMirrorFiyatı = action.payload;
    },
    setSensorFiyatı(state, action) {
      state.sensorFiyatı = action.payload;
    },
    setSocketUsbFiyatı(state, action) {
      state.socketUsbFee = action.payload;
    },
    setSocketUsbDisaridaFiyatı(state, action) {
      state.socketUsbDisaridaFee = action.payload;
    },
    setLedIsikFiyatı(state, action) {
      state.ledIsikFiyatı = action.payload;
    },
    setDerinlikFiyatı(state, action) {
      state.derinlikFee = action.payload;
    },
    setCabinetMirrorName(state, action) {
      state.cabinetMirrorName = action.payload;
    },
    setPandoraLightFee(state, action) {
      state.pandoraLightFee = action.payload;
    },
  },
});

export const {
  setBaseFee,
  setAhsapZeminFiyatı,
  setDekorFiyatı,
  setBackPanelFiyatı,
  kurulumCercevesiFee,
  setBluetoothSpeakerFiyatı,
  setCamRafFiyatı,
  setCabinetMirrorTypeFiyatı,
  setDoorHandleFiyatı,
  setDoorFiyatı,
  setExternalLedFiyatı,
  setMakeupMirrorFiyatı,
  setSensorFiyatı,
  setLedIsikFiyatı,
  setDerinlikFiyatı,
  setKurulumCercevesiFee,
  setCabinetMirrorName,
  setPandoraLightFee,
  setSocketUsbFiyatı,
  setSocketUsbDisaridaFiyatı,
  setLavaboTavanLightSalterFiyatı,
} = cabinetMirrorFee.actions;

export default cabinetMirrorFee.reducer;
