import { Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { changeCabinetMirrorHeight } from "../../../store/cabinetMirror";
import { useState, useEffect } from "react";

const Yukseklik = () => {
  const dispatch = useDispatch();
  const { cabinetMirrorHeight } = useSelector((state) => state.cabinetMirror);

  const { selectedMirror } = useSelector((state) => state.mirror);
  const [alertMessage, setAlertMessage] = useState(false);

  const MAX_VALUE = 1400;
  const MIN_VALUE = 400;
  const [input, setInput] = useState(cabinetMirrorHeight);

  useEffect(() => {
    dispatch(changeCabinetMirrorHeight(MAX_VALUE));
    setInput(MAX_VALUE);
  }, [selectedMirror]);

  const handleChange = (e) => {
    setInput(e.target.value);
    if (e.target.value < MIN_VALUE || e.target.value > MAX_VALUE) {
      setAlertMessage(true);
    } else {
      setAlertMessage(false);
      dispatch(changeCabinetMirrorHeight(e.target.value));
    }
  };

  return (
    <>
      <div>
        <input className="text-center text-lg bg-transparent border-b outline-none focus:bg-transparent border-secondary" type="number" value={input} onChange={handleChange} />
      </div>
      {alertMessage ? (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          sx={{
            position: "relative",
            top: -10,
          }}
        >
          {/* <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem", mt: 0 }}></Typography> */}
          <small
            style={{
              color: "red",
            }}
          >
            Please enter the height value between {MIN_VALUE} and {MAX_VALUE}
          </small>
        </Stack>
      ) : null}
    </>
  );
};

export default Yukseklik;
