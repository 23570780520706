import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { readPriceFromApiForKare } from "../../utils/readJson";
import { setBaseFee } from "../../store/cabinetMirrorFee";

import { BotElements, IconButtons, ProductName, TopElements, ConfigureContainer } from "./items";
import { useMediaQuery } from "@mui/material";

const NewMenu = ({ setMenuButtonIsActive, setProductsMenuButtonIsActive, menuButtonIsActive }) => {
  const { cabinetMirrorWidth, cabinetMirrorHeight, selectedMirrorExcelIndex } = useSelector((state) => state.cabinetMirror);

  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      await readPriceFromApiForKare(selectedMirrorExcelIndex, cabinetMirrorWidth, cabinetMirrorHeight).then((response) => {
        dispatch(setBaseFee(response ? response : 0));
      });
    }

    fetchData();
  }, [selectedMirrorExcelIndex, cabinetMirrorWidth, cabinetMirrorHeight]);

  const [itemClicked, setItemClicked] = useState(false);

  const menuMobileMatches = useMediaQuery("(max-width:1114px)");
  const menuMobileMatches2 = useMediaQuery("(max-width:464px)");
  const menuMobileMatches3 = useMediaQuery("(max-width:431px)");

  return (
    <>
      {menuMobileMatches && !menuButtonIsActive ? (
        <div
          className="absolute flex items-center right-10 top-14"
          onClick={() => {
            setMenuButtonIsActive(true);
            setProductsMenuButtonIsActive(false);
          }}>
          <div class="stroke-primary !fill-primary bg-secondary p-2 rounded-full  cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke=""
              className="w-6 h-6 ">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25"
              />
            </svg>
          </div>
        </div>
      ) : (
        <div className={`absolute flex items-center h-full w-96 ${menuMobileMatches2 ? "right-6 h-screen" : "right-10 py-10"} z-[123456789] ${menuMobileMatches3 ? "!w-full !right-0" : ""} `}>
          <div className="flex flex-col h-[90vh] items-center w-full p-10 bg-white bg-opacity-30 backdrop-blur-xl rounded-3xl font-NeueMontreal gap-7">
            {/* Close button */}
            {menuMobileMatches ? (
              <div
                className="flex self-end"
                onClick={() => setMenuButtonIsActive(false)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 ">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </div>
            ) : null}
            {/* Close button end */}

            <TopElements />
            <ProductName />
            <IconButtons />
            <ConfigureContainer
              itemClicked={itemClicked}
              setItemClicked={setItemClicked}
            />
            <BotElements
              itemClicked={itemClicked}
              setItemClicked={setItemClicked}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default NewMenu;
