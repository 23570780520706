import React from "react";
import Icon from "../Common/Icon";
import { useSelector } from "react-redux";

const TopElements = () => {
  const { cabinetMirrorWidth, cabinetMirrorHeight, orderAmount } = useSelector((state) => state.cabinetMirror);

  const {
    baseFee,
    ahsapZeminFiyatı,
    backPanelFiyatı,
    bluetoothSpeakerFiyatı,
    camRafFiyatı,
    cabinetMirrorTypeFiyatı,
    doorHandleFiyatı,
    doorFiyatı,
    externalLedFiyatı,
    makeupMirrorFiyatı,
    sensorFiyatı,
    dekorFiyati,
    ledIsikFiyatı,
    pandoraLightFee,
    kurulumCercevesiFee,
    lavaboTavanLightSalterFiyatı,
    socketUsbFee,
    socketUsbDisaridaFee,
    derinlikFee,
  } = useSelector((state) => state.cabinetMirrorFee);

  const dimensionFee = cabinetMirrorWidth > 1200 && cabinetMirrorHeight > 800 ? 49.99 : 0;

  const total = parseFloat(
    (baseFee +
      ahsapZeminFiyatı +
      dekorFiyati +
      ledIsikFiyatı +
      backPanelFiyatı +
      bluetoothSpeakerFiyatı +
      camRafFiyatı +
      cabinetMirrorTypeFiyatı +
      doorHandleFiyatı +
      doorFiyatı +
      externalLedFiyatı +
      makeupMirrorFiyatı +
      kurulumCercevesiFee +
      sensorFiyatı +
      dimensionFee +
      pandoraLightFee +
      socketUsbFee +
      lavaboTavanLightSalterFiyatı +
      socketUsbDisaridaFee +
      derinlikFee) *
      orderAmount
  ).toFixed(2);
  return (
    <div className="flex justify-between w-full">
      <div className="relative">
        <Icon className="!p-[10px]" src="/newMenuAssets/basket.png" />
        <div className="absolute flex flex-row items-center justify-center w-4 h-4 text-xs font-bold rounded-full -top-1 -right-1 bg-primary text-secondary color-black">{orderAmount}</div>
      </div>
      <div className="flex items-center justify-center w-24 rounded-full h-9 bg-secondary text-primary "> ${total}</div>
    </div>
  );
};

export default TopElements;
